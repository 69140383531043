import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0a1e1c;
  min-height: 100%;
  padding-bottom: 100px;
  position: relative;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding-bottom: 20px;
`;

export const MenuItemsContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
`;

export const ItemWrapper = styled.div`
  box-shadow: 0.5px 1.5px #b78b2c;
  transition: 0.3s;
  border-radius: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #b78b2c;
  color: #b78b2c;
  display: flex;
  flex-direction: column;
  background-color: #0a1e1c;
  width: 80%;
  height: 80px;
  margin-top: 20px;
`;

export const ImageWrapper = styled.div`
  flex: 1;
  padding: 5px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Image = styled.img`
  margin-block: 10px;
`;

export const Text = styled.p`
  font-size: 12px;
  color: #b78b2c;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
`;

export const Footer = styled.footer`
  width: 100%;
  background-color: #0b1f1c;
  color: #b78b2c;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding: 20px 0;

  p {
    font-size: 12px;
    margin: 0;
  }
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
`;
