import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FoodIcon from "../icons/foodIcon";
import LanguageSwitch from "../languageSwitch/LanguageSwitch";
import {
  Image,
  ImageWrapper,
  ItemWrapper,
  ItemsWrapper,
  MenuItemsContainer,
  Text,
  Wrapper,
} from "./styles";

const HomeScreen = ({ selectedLanguage, changeLanguage }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const MenuItems = [
    {
      image: "/straftaPhotos/bezalkoholnaPica.png",
      text: "KARTA PIĆA",
      navigate: "/drinks",
    },
    { icon: <FoodIcon />, text: "HRANA", navigate: "/food" },
  ];

  const Items = MenuItems.map((item) => {
    return (
      <ItemWrapper onClick={() => navigate(item.navigate)}>
        <ImageWrapper>
          {item.icon && item.icon}
          {item.image && <Image src={item.image} alt="error" width={30} />}
        </ImageWrapper>
        <Text>{t(item.text)}</Text>
      </ItemWrapper>
    );
  });

  return (
    <>
      <div
        style={{
          marginRight: "15px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LanguageSwitch />
      </div>
      <Wrapper>
        <MenuItemsContainer>
          <img
            src="/straftaPhotos/Strafta.png"
            alt="not found"
            width={200}
            loading="lazy"
          />
          <ItemsWrapper>{Items}</ItemsWrapper>
        </MenuItemsContainer>
      </Wrapper>
    </>
  );
};

export default HomeScreen;
