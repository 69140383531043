import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../icons/arrowIcon";
import { SoupsItems } from "./constants";
import {
  Container,
  Header,
  ItemContainer,
  Price,
  TextWrapper,
  Title,
} from "./styles";

export const Soups = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const soupsItems = SoupsItems.map((item) => {
    return (
      <>
        <ItemContainer isFirst={item?.isFirst}>
          <TextWrapper isDescription={true}>
            <span style={{ display: "flex", alignItems: "baseline" }}>
              <Title>{t(item.title)}</Title>
            </span>
          </TextWrapper>
          <div>
            <Price>{item.price}</Price>
          </div>
        </ItemContainer>
      </>
    );
  });

  return (
    <>
      <Header>
        <span
          onClick={() => navigate("/food")}
          style={{ display: "flex", alignItems: "center" }}
        >
          <ArrowIcon />
          <p>{t("Potaž i supe")}</p>
        </span>
      </Header>
      <Container>{soupsItems}</Container>
    </>
  );
};
