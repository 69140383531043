import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0a1e1c;
  min-height: 100%;
  padding-bottom: 100px;
  position: relative;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding-top: 15%;
  padding-bottom: 30px;
`;

export const MenuItemsContainer = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 10fr);
  grid-gap: 15px;
  margin-inline: 10px;
  padding-top: 20px;
`;

export const ItemWrapper = styled.div`
  box-shadow: 0.5px 1.5px #d7b56d9c;
  transition: 0.3s;
  border-radius: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #d9b56f7a;
  color: #b78b2c;
  display: flex;
  flex-direction: column;
  background-color: #0a1e1c;
  width: 100%;
  height: 65px;
`;

export const ImageWrapper = styled.div`
  flex: 1;
  padding: 10px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Image = styled.img`
  margin-block: 10px;
`;

export const Text = styled.p`
  font-size: 10px;
  color: #b78b2c;
  font-weight: 400;
  text-align: center;
  margin-block: 5px;
  margin-inline: 5px;
  text-transform: uppercase;
`;

export const Footer = styled.footer`
  width: 100%;
  background-color: #0b1f1c;
  color: #b78b2c;
  text-align: center;
  position: fixed;
  bottom: 0;
  padding: 10px 0;

  p {
    font-size: 12px;
    margin: 0;
  }
`;

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #0b1f1c;
  color: #b78b2c;
  padding-inline: 10px;
  padding-block: 5px;
  position: fixed;
  top: 0;
  z-index: 1;

  p {
    font-size: 16px;
    margin-left: 10px;
    text-transform: uppercase;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  overflow-y: auto;
  padding-top: 60px;
  padding-bottom: 30px;
`;

export const ItemContainer = styled.div<{ isFirst: any }>`
  display: flex;
  height: 50px;
  padding: 3px;
  align-items: center;
  justify-content: space-between;
  margin-inline: 10px;
  border-top: ${(props) =>
    props.isFirst ? "transparent" : "1px solid rgb(255, 255, 255, 0.3)"};
`;

export const TextWrapper = styled.div<{ isDescription?: any }>`
  display: flex;
  align-items: baseline;
  flex-direction: ${(props) => (props.isDescription ? "column" : "row")};
`;

export const Title = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
`;

export const Subtitle = styled.p`
  margin: 0;
  margin-left: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #b78b2c;
  text-transform: none;
`;

export const Price = styled.p`
  margin: 0;
  color: white;
  font-size: 14px;
  font-weight: 700;
`;

export const SectionTitleWrapper = styled.div`
  margin-block: 10px;
  background-color: rgb(183, 139, 44, 0.7);

  p {
    padding: 15px;
    margin: 0;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 800;
    font-size: 16px;
  }
`;

export const Description = styled.p`
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  color: #b78b2c;
  text-transform: none;
`;
