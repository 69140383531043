import { styled } from "styled-components";

export const SelectComponent = styled.select`
  background-color: transparent;
  border: none;
  padding-left: 10px;
  color: white;
  margin-left: 2px;
  outline: none;
  font-family: "Quattrocento", serif;
`;

export const OptionComponent = styled.option`
  background-color: #b3b3b3;
  border: none;
  font-family: "Quattrocento", serif;
`;

export const OptionText = styled.p`
  padding: 5px;
  font-family: "Quattrocento", serif;
`;

export const ToggleButton = styled.button`
  padding: 12px 12px;
  box-shadow: 0.5px 1.5px #b78b2c;
  transition: 0.3s;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: #b78b2c;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 40px;
  height: 40px;
  margin-block: 20px;
  align-items: center;
  font-size: 14px;

  p {
    margin: 0;
    color: #b78b2c;
    font-size: 12px;
  }
`;
