import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton } from "./styles";

const LanguageToggle: React.FC = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || i18n.language
  );
  const { t } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = selectedLanguage === "en" ? "sr" : "en";
    i18n.changeLanguage(newLanguage);
    setSelectedLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };

  return (
    <div>
      <ToggleButton onClick={toggleLanguage}>
        <p> {selectedLanguage === "en" ? t("SR") : t("EN")}</p>
      </ToggleButton>
    </div>
  );
};

export default LanguageToggle;
