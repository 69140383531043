import React from "react";

const FoodIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511.39 511.91"
      fill="#b78b2c"
      width={35}
      height={35}
    >
      <g data-name="Layer 2">
        <g data-name="food, plate, tray, hand, restaurant, cover, restaurants, food and restaurant">
          <path d="M90.23 511.91H10a10 10 0 01-10-10V361.36a10 10 0 0110-10h80.23a10 10 0 0110 10v140.55a10 10 0 01-10 10zm-70.23-20h60.23V371.36H20z"></path>
          <path d="M252.15 491.83H90.23a10 10 0 01-10-10V361.36a10 10 0 013.59-7.68L144 303.49a10 10 0 016.41-2.32h90.25a30.08 30.08 0 010 60.15h-40.12a10.08 10.08 0 000 20.16h34.84a280.3 280.3 0 00103.29-19.64l83.75-33a80.06 80.06 0 0186.05 17.9 10 10 0 01-1.5 15.37l-141.81 95.26a201.88 201.88 0 01-113.01 34.46zm-151.92-20h151.92A182 182 0 00354 440.77L484.64 353a60 60 0 00-54.88-5.58L346 380.44a300.12 300.12 0 01-110.63 21h-34.83a30.08 30.08 0 010-60.16h40.11a10.08 10.08 0 000-20.15H154L100.23 366z"></path>
          <path d="M392.55 361.32h-192a10 10 0 010-20h192a10 10 0 010 20z"></path>
          <path d="M102.7 361h-.94a90.29 90.29 0 01-81.7-90 10 10 0 0110-10h451.27a10 10 0 0110 10 90.25 90.25 0 01-22.68 59.87c-1.14 1.3-2.41 2.66-3.74 4a87.77 87.77 0 01-6.74 6.09 10 10 0 11-12.62-15.51 67.86 67.86 0 005.21-4.72c1-1 2-2.05 2.88-3.07a70.3 70.3 0 0017-36.65H40.75a69.86 69.86 0 0062.88 60 10 10 0 01-.93 19.99z"></path>
          <path d="M461.28 281a10 10 0 01-10-10c0-101.82-79.35-187.58-180.65-195.23a10 10 0 011.5-20A215.73 215.73 0 01471.28 271a10 10 0 01-10 10zm-411.17 0a10 10 0 01-10-10A215.73 215.73 0 01239.26 55.83a10 10 0 011.5 20C139.46 83.43 60.11 169.19 60.11 271a10 10 0 01-10 10z"></path>
          <path d="M255.69 80.23a40.12 40.12 0 1140.09-40.11 40.14 40.14 0 01-40.09 40.11zm0-60.23a20.12 20.12 0 1020.09 20.12A20.12 20.12 0 00255.69 20z"></path>
        </g>
      </g>
    </svg>
  );
};

export default FoodIcon;
