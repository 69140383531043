import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../icons/arrowIcon";
import { breakfastItem } from "./constants";
import {
  Container,
  Description,
  Header,
  ItemContainer,
  Price,
  Subtitle,
  TextWrapper,
  Title,
} from "./styles";

export const Breakfast = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const breakfastItems = breakfastItem.map((item) => {
    return (
      <>
        <ItemContainer isFirst={item?.isFirst}>
          <TextWrapper isDescription={true}>
            <span style={{ display: "flex", alignItems: "baseline" }}>
              <Title>{t(item.title)}</Title>
              <Subtitle>{item?.subtitle}</Subtitle>
            </span>
            {item.description && (
              <Description>( {t(item.description)} )</Description>
            )}
          </TextWrapper>
          <div>
            <Price>{item.price}</Price>
          </div>
        </ItemContainer>
      </>
    );
  });

  return (
    <>
      <Header>
        <span
          onClick={() => navigate("/food")}
          style={{ display: "flex", alignItems: "center" }}
        >
          <ArrowIcon />
          <p>{t("Doručak")}</p>
        </span>
      </Header>
      <Container>{breakfastItems}</Container>
    </>
  );
};
