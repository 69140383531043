import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../icons/arrowIcon";
import {
  Container,
  Header,
  ItemContainer,
  Price,
  Subtitle,
  TextWrapper,
  Title,
} from "./styles";

export const HotDrinks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const hotDrinkItems = [
    {
      title: "Topla čokolada",
      subtitle: "0,2",
      price: "2,00€",
      isFirst: true,
    },
    {
      title: "Bijela kafa",
      subtitle: "0,2",
      price: "1,70€",
    },
    {
      title: "Ness kafa",
      subtitle: "0,2",
      price: "2,00€",
    },
    {
      title: "Čaj",
      subtitle: "0,2",
      price: "1,60€",
    },
    {
      title: "Cappuccino",
      subtitle: "0,05",
      price: "1,70€",
    },
    {
      title: "Espresso",
      subtitle: "0,03",
      price: "1,50€",
    },
    {
      title: "Espresso sa šlagom",
      subtitle: "0,04",
      price: "1,70€",
    },
    {
      title: "Espresso sa mlijekom",
      subtitle: "0,04",
      price: "1,60€",
    },
    {
      title: "Kuvana kafa",
      subtitle: "0,05",
      price: "1,20€",
    },
    {
      title: "Late caffe",
      price: "1,70€",
    },
  ];

  const Items = hotDrinkItems.map((item) => {
    return (
      <ItemContainer isFirst={item?.isFirst}>
        <TextWrapper>
          <Title>{t(item.title)}</Title>
          <Subtitle>{item?.subtitle}</Subtitle>
        </TextWrapper>
        <div>
          <Price>{item.price}</Price>
        </div>
      </ItemContainer>
    );
  });
  return (
    <>
      <Header>
        <span
          onClick={() => navigate("/drinks")}
          style={{ display: "flex", alignItems: "center" }}
        >
          <ArrowIcon />
          <p>{t("TOPLI NAPICI")}</p>
        </span>
      </Header>
      <Container>{Items}</Container>
    </>
  );
};
