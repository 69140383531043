import React from "react";

const StartersIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 50 50"
      fill="#b78b2c"
      width={45}
      height={45}
    >
      <path d="M41 36h-1.18a3 3 0 00-2-3.86A4.93 4.93 0 0037 25a5 5 0 000-8 5 5 0 00-2-8.9V1a1 1 0 00-2 0v7.1a5 5 0 00-2 8.9 5 5 0 000 8 4.93 4.93 0 00-.89 7.14 3 3 0 00-2 3.86h-4.29a2.87 2.87 0 000-2H25a1 1 0 001-1v-4a1 1 0 00-1-1h-4.18a2.87 2.87 0 000-2H23a1 1 0 001-1v-4a1 1 0 00-1-1h-1.18A3 3 0 0019 16h-4V1a1 1 0 00-2 0v15H9a3 3 0 00-2.82 4H5a1 1 0 00-1 1v4a1 1 0 001 1h2.18a2.87 2.87 0 000 2H3a1 1 0 00-1 1v4a1 1 0 001 1h1.18a2.87 2.87 0 000 2H1a1 1 0 00-1 1v4a1 1 0 001 1h40a1 1 0 001-1v-4a1 1 0 00-1-1zM31 13a3 3 0 113 3 3 3 0 01-3-3zm0 8a3 3 0 113 3 3 3 0 01-3-3zm3 5a3 3 0 11-3 3 3 3 0 013-3zm-4 9a1 1 0 011-1h6a1 1 0 010 2h-6a1 1 0 01-1-1zM8 19a1 1 0 011-1h10a1 1 0 010 2H9a1 1 0 01-1-1zm-2 3h16v2H6zm3 5a1 1 0 011-1h8a1 1 0 010 2h-8a1 1 0 01-1-1zm-5 3h20v2H4zm2 5a1 1 0 011-1h14a1 1 0 010 2H7a1 1 0 01-1-1zm34 5H2v-2h38z"></path>
    </svg>
  );
};

export default StartersIcon;
