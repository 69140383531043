import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    sr: {
      translation: {
        Copyright: "Copyright © 2024 Pixelvizija | Sva prava zadržana.",
        "KARTA PIĆA": "KARTA PIĆA",
        HRANA: "JELOVNIK",
        "TOPLI NAPICI": "TOPLI NAPICI",
        "BEZALKOHOLNA PIĆA": "BEZALKOHOLNA PIĆA",
        "ALKOHOLNA PIĆA": "ALKOHOLNA PIĆA",
        PIVA: "PIVA",
        SALATE: "SALATE",
        "VINSKA KARTA": "VINSKA KARTA",
        "Topla čokolada": "Topla čokolada",
        "Bijela kafa": "Bijela kafa",
        "Ness kafa": "Ness kafa",
        Čaj: "Čaj",
        Cappuccino: "Cappuccino",
        Espresso: "Espresso",
        "Espresso sa šlagom": "Espresso sa šlagom",
        "Espresso sa mlijekom": "Espresso sa mlijekom",
        "Kuvana kafa": "Kuvana kafa",
        "Late caffe": "Late caffe",
        "Prirodni sokovi": "Prirodni sokovi",
        Limunada: "Limunada",
        "Cijedjena pomorandža": "Cijeđena pomorandža",
        Mix: "Mix",
        Green: "Green One 100% voće",
        detox: "detox, imuno, mix",
        "Bezalkoholna pića": "Bezalkoholna pića",
        "Coca cola": "Coca cola",
        "Coca cola zero": "Coca cola zero",
        Fanta: "Fanta",
        Sprite: "Sprite",
        "Biter lemon": "Biter lemon",
        Tonic: "Tonic",
        "Ledeni čaj": "Ledeni čaj",
        Cedevita: "Cedevita",
        Rosa: "Rosa",
        Kisjela: "Kisjela",
        Vode: "Vode",
        "Energetska pića": "Energetska pića",
        "Red bull": "Red bull",
        Guarana: "Guarana",
        Šejk: "Šejk",
        Banana: "Banana",
        Plazma: "Plazma",
        Nikšićko: "Nikšićko",
        Corona: "Corona",
        Bavaria: "Bavaria",
        "Stela artois": "Stela artois",
        Somersby: "Somersby",
        Vodke: "Vodke",
        Smirnoff: "Smirnoff",
        "Grey Goose": "Grey Goose",
        Belvedere: "Belvedere",
        "Smirnoff Ice": "Smirnoff Ice",
        Rakije: "Rakije",
        Šamar: "Šamar",
        Meduška: "Meduška",
        "Loza institut": "Loza institut",
        Viljamovka: "Viljamovka",
        Dunja: "Dunja",
        Kajsija: "Kajsija",
        "Premium šljiva": "Premium šljiva",
        "Žestoka pića": "Žestoka pića",
        Vinjak: "Vinjak",
        Štok: "Štok",
        Gin: "Gin",
        Tekila: "Tekila",
        Konjak: "Konjak",
        Courvoisier: "Courvoisier",
        "Hennesy V.S.O.P": "Hennesy V.S.O.P",
        Burbon: "Burbon",
        "Jim Beam White": "Jim Beam Whit",
        "Jim Beam Black": "Jim Beam Black",
        "Jack Daniels": "Jack Daniels",
        "Jack Daniels single barel": "Jack Daniels single barel",
        Viski: "Viski",
        Jameson: "Jameson",
        "Johnny walker red": "Johnny walker red",
        "Johnny walker black": "Johnny walker black",
        Chivas: "Chivas",
        "Chivas Regal /18 y.o ": "Chivas Regal /18 y.o",
        "Ballantine's": "Ballantine's",
        Likeri: "Likeri",
        Jagermeister: "Jägermeister",
        Martini: "Martini",
        Baileys: "Baileys",
        "Amaro Montenegro": "Amaro Montenegro",
        Pelinkovac: "Pelinkovac",
        Aperol: "Aperol",
        Šampanjac: "Šampanjac",
        Trockenbau: "Trockenbau",
        Prosecco: "Prosecco",
        Moet: "Moet",
        "Bijela vina": "Bijela vina",
        Chardonny: "Chardonny",
        "Chardonny Barrique": "Chardonny Barrique",
        "Chardonny  Kovačević": "Chardonny Kovačević",
        "Pinot  Grigio": "Pinot  Grigio",
        "Chardonny Radovanović": "Chardonny Radovanović",
        "Chardonny Plantaže": "Chardonny Plantaže",
        Malvazija: "Malvazija",
        "Crna vina": "Crna vina",
        "Vranac Pro Corde": "Vranac Pro Corde",
        Epoha: "Epoha",
        Vladika: "Vladika",
        "Vranac Barrique": "Vranac Barrique",
        "Vranac Reserve": "Vranac Reserve",
        "Stari Podrum": "Stari Podrum ",
        Premijer: "Premijer",
        "Pro Corde": "Pro Corde",
        Vranac: "Vranac",
        "Kupinovo vino": "Kupinovo vino",
        "Rose vina": "Rose vina",
        "Crnogorski Roze": "Crnogorski Roze",
        "Radovanović Roze": "Radovanović Roze",
        "Roze Val": "Roze Val",
        "Roze Plantaže": "Roze Plantaže",
        "Glavna jela": "Glavna jela",
        "Biftek na žaru": "Biftek na žaru",
        "biftek, pire od tartufa": "biftek, pire od tartufa",
        "Biftek u ulju": "Biftek u ulju",
        "biftek, pire": "biftek, pire",
        "Biftek u sosu": "Biftek u sosu",
        "-gorgonzole  -pečuraka   -4 vrste sira   -pomorandže":
          "-gorgonzole  -pečuraka   -4 vrste sira   -pomorandže",
        "Teleći medaljoni": "Teleći medaljoni",
        "teleće meso, neutralna pavlaka, pečurke, aromatični krompir":
          "teleće meso, neutralna pavlaka, pečurke, aromatični krompir",
        Ramstek: "Ramstek",
        "Teleće meso, pirinač, povrće": "Teleće meso, pirinač, povrće",
        "Punjeni pileći stek u sosu od pomorandže":
          "Punjeni pileći stek u sosu od pomorandže",
        "piletina, pršuta, sir, pire, sos": "piletina, pršuta, sir, pire, sos",
        "Batak u sosu": "Batak u sosu ",
        "batak, pire, cary sos": "batak, pire, cary sos",
        "Saltimboka Štrafta": "Saltimboka Štrafta",
        "svinjski vrat, pršuta, parmezan, soya sos, aromatični krompir":
          "svinjski vrat, pršuta, parmezan, soya sos, aromatični krompir",
        "Vok Šangaj": "Vok Šangaj",
        "3 vrste mesa, nudle, miks povrća, kikiriki, susam":
          "3 vrste mesa, nudle, miks povrća, kikiriki, susam",
        "Hrskava piletina": "Hrskava piletina",
        "piletina,  kroketi, tartar sos, kornifleks":
          "piletina,  kroketi, tartar sos, kornifleks",
        Ražnjići: "Ražnjići",
        "Piletina, pančeta, paprika, pomfrit":
          "Piletina, pančeta, paprika, pomfrit",
        "Piletina u gorgonzoli": "Piletina u gorgonzoli",
        "piletina, pirinač, pomfrit, gorgonzola, neutralna pavlaka":
          "piletina, pirinač, pomfrit, gorgonzola, neutralna pavlaka",
        "Piletina u sosu od spanaća": "Piletina u sosu od spanaća",
        "Piletina sa susamom i bademom": "Piletina sa susamom i bademom",
        "piletina, neutrala pavlaka, susam, badem":
          "piletina, neutrala pavlaka, susam, badem",
        "piletina, spanać, pomfrit, neutralna pavlaka":
          "piletina, spanać, pomfrit, neutralna pavlaka",
        "Jela od ribe": "Jela od ribe",
        "Losos na žaru": "Losos na žaru",
        "Losos, mediteransko varivo": "Losos, mediteransko varivo",
        "Tuna stek": "Tuna stek",
        "tuna, mediteransko varivo": "tuna, mediteransko varivo",
        "Lignje na žaru": "Lignje na žaru",
        "lignje, dalmatinsko varivo": "lignje, dalmatinsko varivo",
        Prilozi: "Prilozi",
        Pomfrit: "Pomfrit",
        "Ekstra prilog": "Ekstra prilog",
        Masline: "Masline",
        "Pica hljeb": "Pica hljeb",
        Fokaća: "Fokaća",
        "Sos po želji": "Sos po želji",
        Kajmak: "Kajmak",
        Pršuta: "Pršuta",
        Dezerti: "Dezerti",
        Palačinka: "Palačinka",
        "nutela, plazma": "nutela, plazma",
        "Američke palačinke": "Američke palačinke",
        klasik: "klasik",
        "eurokrem,plazma": "eurokrem, plazma",
        "banananom i bademom, eurokrem,plazma":
          "banananom i bademom, eurokrem,plazma",
        "Američke palačinke sa voćem": "Američke palačinke sa voćem",
        "eurokrem, voće, šlag": "eurokrem, voće, šlag",
        Oreo: "Oreo",
        "oreo keks, abc sir, bijela čokolada, slatka pavlaka":
          "oreo keks, abc sir, bijela čokolada, slatka pavlaka",
        "Bluebery cake": "Bluebery cake",
        "plazma, bijela čokolada, grčki jogurt, borovnice":
          "plazma, bijela čokolada, grčki jogurt, borovnice",
        "Jagode sa šlagom": "Jagode sa šlagom",
        "Voćna salata": "Voćna salata",
        "jagoda, breskva, banana, borovnica, kiwi, jabuka i kupina, šlag":
          "jagoda, breskva, banana, borovnica, kiwi, jabuka i kupina, šlag",
        Doručak: "Doručak",
        "Štrafta doručak": "Štrafta doručak",
        "Jaja, tost, avokado, pršuta, pohovani kačkavalj":
          "Jaja, tost, avokado, pršuta, pohovani kačkavalj",
        "Benedikt jaja slanina": "Benedikt jaja slanina",
        "poširana jaja, tost, gvakamole sos, holandez sos":
          "poširana jaja, tost, gvakamole sos, holandez sos",
        "Benedikt jaja pršuta": "Benedikt jaja pršuta",
        "Benedikt jaja losos": "Benedikt jaja losos",
        "Omlet iz Đenove": "Omlet iz Đenove",
        "jaja, tost, šunka, trapist, pesto sos":
          "jaja, tost, šunka, trapist, pesto sos",
        "Omlet sa sirom": "Omlet sa sirom",
        "Jaja, trapist, pavlaka": "Jaja, trapist, pavlaka",
        "Omlet sa šunkom i sirom": "Omlet sa šunkom i sirom",
        "jaja, šunka, trapist , pavlaka": "jaja, šunka, trapist , pavlaka",
        "Omlet sa povrćem": "Omlet sa povrćem",
        "jaja, trapist, povrće": "jaja, trapist, povrće",
        "Sport doručak": "Sport doručak",
        "bjelanca, tikvica, losos, pavlaka":
          "bjelanca, tikvica, losos, pavlaka",
        "Engleski doručak": "Engleski doručak",
        "3 jaja, 2 kobasice, feta": "3 jaja, 2 kobasice, feta",
        "Puter kiflice": "Puter kiflice",
        "sir, pršut, trapist, tartar sos": "sir, pršut, trapist, tartar sos",
        "Punjene prženice": "Punjene prženice",
        "šunka, trapist, pavlaka": "šunka, trapist, pavlaka",
        "Štapići na kajmaku": "Štapići na kajmaku",
        "piletina, kajmak, lepinja/ svinjski vrat, kajmak, lepinja":
          "piletina, kajmak, lepinja/ svinjski vrat, kajmak, lepinja",
        Starters: "Starters",
        "Starters Štrafta": "Starters Štrafta",
        "svinjski i govedji pršut, kulen, gorgonzola, mocarela, parmezan, badem, feta, masline, čeri, fokaća":
          "svinjski i govedji pršut, kulen, gorgonzola, mocarela, parmezan, badem, feta, masline, čeri, fokaća",
        "Selekcija sireva": "Selekcija sireva",
        "gorgonzola, mocarela, gauda, feta, parmezan, masline, indijski orah, čeri":
          "gorgonzola, mocarela, gauda, feta, parmezan, masline, indijski orah, čeri",
        "Pohovani kačkavalj": "Pohovani kačkavalj",
        "sir, pomfrit, tartar sos": "sir, pomfrit, tartar sos",
        Brusketi: "Brusketi",
        "mocarela, paradajz, masline": "mocarela, paradajz, masline",
        "Grilovano povrće": "Grilovano povrće",
        "Potaž i supe": "Potaž i supe",
        "Teleća čorba": "Teleća čorba",
        "Punjene pečurke": "Punjene pečurke",
        "pečurke, riža, sir, pršut": "pečurke, riža, sir, pršut",
        Minestrone: "Minestrone",
        "Potaž od paradajza": "Potaž od paradajza",
        "Obrok salate": "Obrok salate",
        "Ala Chef": "Ala Chef",
        "salata, piletina, pečurke, dimljeni sir, šunka, dresing":
          "salata, piletina, pečurke, dimljeni sir, šunka, dresing",
        "Biftek salata": "Biftek salata",
        "miks salata, dresing, biftek, mocarela, badem, krutoni, parmezan":
          " miks salata, dresing, biftek, mocarela, badem, krutoni, parmezan",
        "Štrafta salata": "Štrafta salata",
        "miks salata, dresing, pohovana feta, hrskava piletina, indijski orah,krutoni, parmezan":
          "miks salata, dresing, pohovana feta, hrskava piletina, indijski orah,krutoni, parmezan",
        "Cezar salata": "Cezar salata",
        "miks salata, dresing, mocarela, piletina, slanina, krutoni,badem parmezan":
          "miks salata, dresing, mocarela, piletina, slanina, krutoni,badem parmezan",
        "Losos salata": "Losos salata",
        "miks salata, dresing, avokado, dimljeni losos, sjemenke bundeve":
          "miks salata, dresing, avokado, dimljeni losos, sjemenke bundeve",
        "Caprese salata": "Caprese salata",
        "mocarela, paradajz, pesto sos, bosiljak":
          "mocarela, paradajz, pesto sos, bosiljak",
        "Sezonske salate": "Sezonske salate",
        "Šopska salata": "Šopska salata",
        "Miks salata": "Miks salata",
        "Paradajz salata": "Paradajz salata",
        "Sendviči i tortilje": "Sendviči i tortilje",
        "Sendvič Štrafta": "Sendvič Štrafta",
        "lepinja, šunka, topljeni sir, pesto sos, rukola, pomfrit":
          "lepinja, šunka, topljeni sir, pesto sos, rukola, pomfrit",
        "Sendvič Sicilija": "Sendvič Sicilija",
        "Lepinja, namaz, pršuta, sir, salata, pomfrit":
          "Lepinja, namaz, pršuta, sir, salata, pomfrit",
        "Pizza sendvič": "Pizza sendvič",
        "lepinja, namaz, pršuta, pomfrit": "lepinja, namaz, pršuta, pomfrit",
        "Rich sendvič": "Rich sendvič",
        "lepinja, trapist, pohovana piletina, pomfrit":
          "lepinja, trapist, pohovana piletina, pomfrit",
        "Klub sendvič": "Klub sendvič",
        "tost, piletina, jaje, šunka, trapist, pomfrit":
          "tost, piletina, jaje, šunka, trapist, pomfrit",
        TORTILJE: "TORTILJE",
        "Tortilja u cary sosu": "Tortilja u cary sosu",
        "domaća tortilja, piletina, neutralna pavlaka, zelena salata paradajz, pomfrit":
          "domaća tortilja, piletina, neutralna pavlaka, zelena salata paradajz, pomfrit",
        "Tortilja u pesto sosu": "Tortilja u pesto sosu",
        "domaća tortilja, sir, piletina, salata, pesto sos, pomfrit":
          "domaća tortilja, sir, piletina, salata, pesto sos, pomfrit",
        "Tortilja piletina i gvakamole": "Tortilja piletina i gvakamole",
        "domaća tortilja, gvakamole sos, piletina, salata, pomfrit":
          "domaća tortilja, gvakamole sos, piletina, salata, pomfrit",
        "Tortilja tuna": "Tortilja tuna",
        "domaća tortilja, namaz, tunjevina, salata, pomfrit":
          "domaća tortilja, namaz, tunjevina, salata, pomfrit",
        Pizze: "Pizze",
        "pelat, sir, pršuta, šunka, pančeta, kulen, pečurke, kajmak":
          "pelat, sir, pršuta, šunka, pančeta, kulen, pečurke, kajmak",
        Margarita: "Margarita",
        "sir, pelat, bosiljak": "sir, pelat, bosiljak",
        Capricosa: "Capricosa",
        "pelat, sir, šunka, pečurke": "pelat, sir, šunka, pečurke",
        Pepperoni: "Pepperoni",
        "pelat, sir, kulen, ljuta paprika": "pelat, sir, kulen, ljuta paprika",
        Tonno: "Tonno",
        "pelat, sir, tunjevina, luk": "pelat, sir, tunjevina, luk",
        Toscana: "Toscana",
        "pelat, sir, pršuta, gorgonzola, rukola":
          "pelat, sir, pršuta, gorgonzola, rukola",
        "Quatro formagi": "Quatro formagi",
        "pelat, gauda, gorgonzola, mocarela, parmeza":
          "pelat, gauda, gorgonzola, mocarela, parmeza",
        Vegeteriana: "Vegeteriana",
        "pelat, sir, povrće": "pelat, sir, povrće",
        Calcone: "Calcone",
        Pasta: "Pasta",
        Rižoto: "Rižoto",
        "Paste i rižota": "Paste i rižota",
        "Ala Chef pasta": "Ala Chef pasta",
        "piletina, pečurke,tikvice, neutralna pavlaka":
          "piletina, pečurke,tikvice, neutralna pavlaka",
        "Pasta ai frutti di mare": "Pasta ai frutti di mare",
        "morski plodovi, pasta, crveni sos":
          "morski plodovi, pasta, crveni sos",
        "Arabiata pasta": "Arabiata pasta",
        "pasta, paprika, pelat, čili": "pasta, paprika, pelat, čili",
        "Tagliatelle carbonara": "Tagliatelle carbonara",
        "slanina, pavlaka neutralna, parmezan, jaje":
          "slanina, pavlaka neutralna, parmezan, jaje",
        "Spaghetti Pollo e Pesto": "Spaghetti Pollo e Pesto",
        "piletina, neutralna pavlaka, pesto sos, indijski orah":
          "piletina, neutralna pavlaka, pesto sos, indijski orah",
        "Njoke Formaggio e Pollo": "Njoke Formaggio e Pollo",
        "piletina, 4 vrste sira, badem, njoke":
          "piletina, 4 vrste sira, badem, njoke",
        "Farfalle Vittelo e Fungi": "Farfalle Vittelo e Fungi",
        "biftek, neutralna pavlaka, pečurke, soya sos, parmezan":
          "biftek, neutralna pavlaka, pečurke, soya sos, parmezan",
        "Tagliatelle  di tacchino arrosto": "Tagliatelle  di tacchino arrosto",
        "pečurke, piletina, neutralna pavlaka, mocarela":
          "pečurke, piletina, neutralna pavlaka, mocarela",
        "Risotto Formagio e Pollo": "Risotto Formagio e Pollo",
        "piletina, 4 vrste sira, neutralna pavlaka, parmezan":
          "piletina, 4 vrste sira, neutralna pavlaka, parmezan",
        "Risotto tacchino e Pesto": "Risotto tacchino e Pesto",
        "piletina, pesto sos, neutralna pavlaka, parmezan":
          "piletina, pesto sos, neutralna pavlaka, parmezan",
        "Risotto Vittelo e Fung": "Risotto Vittelo e Fung",
        "biftek, sos od pečurki, neutralna pavlaka, riža":
          "biftek, sos od pečurki, neutralna pavlaka, riža",
        "Risotto nero ai frutti di mare": "Risotto nero ai frutti di mare",
        "crnilo od sipe, morski plodovi, riža":
          "crnilo od sipe, morski plodovi, riža",
        "Risotto Rosso ai frutti di mare": "Risotto Rosso ai frutti di mare",
        "crveni sos, morski plodovi, riža": "crveni sos, morski plodovi, riža",
      },
    },
    en: {
      translation: {
        "KARTA PIĆA": "DRINKS MENU",
        HRANA: "FOOD MENU",
        "TOPLI NAPICI": "HOT DRINKS",
        "BEZALKOHOLNA PIĆA": "SOFT DRINKS",
        "ALKOHOLNA PIĆA": "ALCOHOLIC DRINKS",
        PIVA: "BEERS",
        "VINSKA KARTA": "WINE LIST",
        "Topla čokolada": "Hot Chocolate",
        "Bijela kafa": "White Coffee",
        "Ness kafa": "Nescafe",
        Čaj: "Tea",
        Cappuccino: "Cappuccino",
        Espresso: "Espresso",
        "Espresso sa šlagom": "Espresso with whipped cream",
        "Espresso sa mlijekom": "Espresso with milk",
        "Kuvana kafa": "Brewed coffee",
        "Late caffe": "Latte Caffè",
        "Prirodni sokovi": "Natural Juices",
        Limunada: "Lemonade",
        "Cijedjena pomorandža": "Fresh Orange Juice",
        Mix: "Mix",
        Green: "Green One 100% Fruit",
        detox: "Detox, Immune, Mix",
        "Bezalkoholna pića": "Soft Drinks",
        "Coca cola": "Coca Cola",
        "Coca cola zero": "Coca Cola Zero",
        Fanta: "Fanta",
        Sprite: "Sprite",
        "Biter lemon": "Bitter Lemon",
        Tonic: "Tonic",
        "Ledeni čaj": "Iced Tea",
        Cedevita: "Cedevita",
        Rosa: "Rosa",
        Kisjela: "Sparkling water",
        Vode: "Waters",
        "Energetska pića": "Energy Drinks",
        "Red bull": "Red Bull",
        Guarana: "Guarana",
        Šejk: "Shake",
        Banana: "Banana",
        Plazma: "Plazma",
        Nikšićko: "Nikšićko",
        Corona: "Corona",
        Bavaria: "Bavaria",
        "Stela artois": "Stella Artois",
        Somersby: "Somersby",
        Vodke: "Vodkas",
        Smirnoff: "Smirnoff",
        "Grey Goose": "Grey Goose",
        Belvedere: "Belvedere",
        "Smirnoff Ice": "Smirnoff Ice",
        Rakije: "Brandies",
        Šamar: "Slap",
        Meduška: "Jellyfish",
        "Loza institut": "Loza Institute",
        Viljamovka: "Viljamovka",
        Dunja: "Quince",
        Kajsija: "Apricot",
        "Premium šljiva": "Premium Plum",
        "Žestoka pića": "Strong Spirits",
        Vinjak: "Brandy",
        Štok: "Štok",
        Gin: "Gin",
        Tekila: "Tequila",
        Konjak: "Cognac",
        "Hennesy V.S.O.P": "Hennesy V.S.O.P",
        Burbon: "Bourbon",
        "Jim Beam White": "Jim Beam White",
        "Jim Beam Black": "Jim Beam Black",
        "Jack Daniels": "Jack Daniels",
        "Jack Daniels single barel": "Jack Daniels Single Barrel",
        Viski: "Whiskey",
        Jameson: "Jameson",
        "Johnny walker red": "Johnny Walker Red",
        "Johnny walker black": "Johnny Walker Black",
        Chivas: "Chivas",
        "Chivas Regal /18 y.o ": "Chivas Regal /18 y.o",
        "Ballantine's": "Ballantine's",
        Likeri: "Liqueurs",
        Jagermeister: "Jägermeister",
        Martini: "Martini",
        Baileys: "Baileys",
        "Amaro Montenegro": "Amaro Montenegro",
        Pelinkovac: "Pelinkovac",
        Aperol: "Aperol",
        Šampanjac: "Champagne",
        Trockenbau: "Trockenbau",
        Prosecco: "Prosecco",
        Moet: "Moet",
        "Bijela vina": "White Wines",
        Chardonny: "Chardonnay",
        "Chardonny Barrique": "Chardonnay Barrique",
        "Chardonny  Kovačević": "Chardonnay Kovačević",
        "Pinot  Grigio": "Pinot Grigio",
        "Chardonny Radovanović": "Chardonnay Radovanović",
        "Chardonny Plantaže": "Chardonnay Plantaže",
        Malvazija: "Malvasia",
        "Crna vina": "Red Wines",
        "Vranac Pro Corde": "Vranac Pro Corde",
        Epoha: "Epoch",
        Vladika: "Vladika",
        "Vranac Barrique": "Vranac Barrique",
        "Vranac Reserve": "Vranac Reserve",
        "Stari Podrum": "Old Cellar",
        Premijer: "Premier",
        "Pro Corde": "Pro Corde",
        Vranac: "Vranac",
        "Kupinovo vino": "Blackberry Wine",
        "Rose vina": "Rosé Wines",
        "Crnogorski Roze": "Montenegrin Rosé",
        "Radovanović Roze": "Radovanović Rosé",
        "Roze Val": "Rosé Val",
        "Roze Plantaže": "Rosé Plantaže",
        "Glavna jela": "Main dishes",
        "Biftek na žaru": "Grilled steak",
        "biftek, pire od tartufa": "steak, truffle mash",
        "Biftek u ulju": "Steak in oil",
        "biftek, pire": "steak, mash",
        "Biftek u sosu": "Steak in sauce",
        "-gorgonzole  -pečuraka   -4 vrste sira   -pomorandže":
          "-gorgonzola  -mushrooms   -4 types of cheese   -oranges",
        "Teleći medaljoni": "Veal medallions",
        "teleće meso, neutralna pavlaka, pečurke, aromatični krompir":
          "veal meat, sour cream, mushrooms, aromatic potatoes",
        Ramstek: "Rump steak",
        "Teleće meso, pirinač, povrće": "Veal meat, rice, vegetables",
        "Punjeni pileći stek u sosu od pomorandže":
          "Stuffed chicken steak in orange sauce",
        "piletina, pršuta, sir, pire, sos":
          "chicken, prosciutto, cheese, mash, sauce",
        "Batak u sosu": "Leg in sauce",
        "batak, pire, cary sos": "leg, mash, cary sauce",
        "Saltimboka Štrafta": "Saltimboka Strafta",
        "svinjski vrat, pršuta, parmezan, soya sos, aromatični krompir":
          "pork neck, prosciutto, parmesan, soy sauce, aromatic potatoes",
        "Vok Šangaj": "Wok Shanghai",
        "3 vrste mesa, nudle, miks povrća, kikiriki, susam":
          "3 types of meat, noodles, mixed vegetables, peanuts, sesame",
        "Hrskava piletina": "Crispy chicken",
        "piletina,  kroketi, tartar sos, kornifleks":
          "chicken, croquettes, tartar sauce, cornflakes",
        Ražnjići: "Kebabs",
        "Piletina, pančeta, paprika, pomfrit":
          "Chicken, bacon, bell pepper, french fries",
        "Piletina u gorgonzoli": "Chicken in gorgonzola",
        "piletina, pirinač, pomfrit, gorgonzola, neutralna pavlaka":
          "chicken, rice, french fries, gorgonzola, sour cream",
        "Piletina u sosu od spanaća": "Chicken in spinach sauce",
        "Piletina sa susamom i bademom": "Chicken with sesame and almonds",
        "piletina, neutrala pavlaka, susam, badem":
          "chicken, sour cream, sesame, almonds",
        "piletina, spanać, pomfrit, neutralna pavlaka":
          "chicken, spinach, french fries, sour cream",
        "Jela od ribe": "Fish dishes",
        "Losos na žaru": "Grilled salmon",
        "Losos, mediteransko varivo": "Salmon, Mediterranean stew",
        "Tuna stek": "Tuna steak",
        "tuna, mediteransko varivo": "tuna, Mediterranean stew",
        "Lignje na žaru": "Grilled squid",
        "lignje, dalmatinsko varivo": "squid, Dalmatian stew",
        Prilozi: "Side dishes",
        Pomfrit: "French fries",
        "Ekstra prilog": "Extra side",
        Masline: "Olives",
        "Pica hljeb": "Pizza bread",
        Fokaća: "Focaccia",
        "Sos po želji": "Sauce of choice",
        Kajmak: "Clotted cream",
        Pršuta: "Prosciutto",
        Dezerti: "Desserts",
        Palačinka: "Pancake",
        "nutela, plazma": "nutella, plazma",
        "Američke palačinke": "American pancakes",
        klasik: "classic",
        "eurokrem, plazma": "eurocream, plazma",
        "banananom i bademom, eurokrem, plazma":
          "banana and almond, eurocream, plazma",
        "Američke palačinke sa voćem": "American pancakes with fruit",
        "eurokrem, voće, šlag": "eurocream, fruit, whipped cream",
        Oreo: "Oreo",
        "oreo keks, abc sir, bijela čokolada, slatka pavlaka":
          "oreo cookies, cream cheese, white chocolate, sweet cream",
        "Bluebery cake": "Blueberry cake",
        "plazma, bijela čokolada, grčki jogurt, borovnice":
          "plazma, white chocolate, greek yogurt, blueberries",
        "Jagode sa šlagom": "Strawberries with whipped cream",
        "Voćna salata": "Fruit salad",
        "jagoda, breskva, banana, borovnica, kiwi, jabuka i kupina, šlag":
          "strawberry, peach, banana, blueberry, kiwi, apple and blackberry, whipped cream",
        Doručak: "Breakfast",
        "Štrafta doručak": "Štrafta breakfast",
        "Jaja, tost, avokado, pršuta, pohovani kačkavalj":
          "Eggs, toast, avocado, prosciutto, breaded cheese",
        "Benedikt jaja slanina": "Benedict eggs bacon",
        "poširana jaja, tost, gvakamole sos, holandez sos":
          "poached eggs, toast, guacamole sauce, hollandaise sauce.",
        "Benedikt jaja pršuta": "Benedict eggs ham",
        "Benedikt jaja losos": "Benedict eggs salmon",
        "Omlet iz Đenove": "Genovese omelette",
        "jaja, tost, šunka, trapist, pesto sos":
          "eggs, toast, ham, Emmental cheese, pesto sauce",
        "Omlet sa sirom": "Cheese omelette",
        "Jaja, trapist, pavlaka": "Eggs, Emmental cheese, cream",
        "Omlet sa šunkom i sirom": "Ham and cheese omelette",
        "jaja, šunka, trapist , pavlaka": "eggs, ham, Emmental cheese, cream",
        "Omlet sa povrćem": "Vegetable omelette",
        "jaja, trapist, povrće": "eggs, Emmental cheese, vegetables",
        "Sport doručak": "Sports breakfast",
        "bjelanca, tikvica, losos, pavlaka":
          "egg whites, zucchini, salmon, cream",
        "Engleski doručak": "English breakfast",
        "3 jaja, 2 kobasice, feta": "3 eggs, 2 sausages, feta cheese",
        "Puter kiflice": "Butter croissants",
        "sir, pršut, trapist, tartar sos":
          "cheese, prosciutto, Emmental cheese, tartar sauce",
        "Punjene prženice": "Stuffed French toast",
        "šunka, trapist, pavlaka": "ham, Emmental cheese, cream",
        "Štapići na kajmaku": "Sticks on clotted cream",
        "piletina, kajmak, lepinja/ svinjski vrat, kajmak, lepinja":
          "chicken, clotted cream, lepinja / pork neck, clotted cream, lepinja",
        Starters: "Starters",
        "Starters Štrafta": "Starters Štrafta",
        "svinjski i govedji pršut, kulen, gorgonzola, mocarela, parmezan, badem, feta, masline, čeri, fokaća":
          "pork and beef prosciutto, sausage, gorgonzola, mozzarella, parmesan, almonds, feta, olives, cherry tomatoes, focaccia",
        "Selekcija sireva": "Cheese selection",
        "gorgonzola, mocarela, gauda, feta, parmezan, masline, indijski orah, čeri":
          "gorgonzola, mozzarella, gouda, feta, parmesan, olives, cashews, cherry tomatoes",
        "Pohovani kačkavalj": "Breaded cheese",
        "sir, pomfrit, tartar sos": "cheese, french fries, tartar sauce",
        Brusketi: "Bruschetta",
        "mocarela, paradajz, masline": "mozzarella, tomato, olives",
        "Grilovano povrće": "Grilled vegetables",
        "Potaž i supe": "Soups",
        "Teleća čorba": "Veal soup",
        "Punjene pečurke": "Stuffed mushrooms",
        "pečurke, riža, sir, pršut": "mushrooms, rice, cheese, prosciutto",
        Minestrone: "Minestrone",
        "Potaž od paradajza": "Tomato soup",
        "Obrok salate": "Meal salads",
        "Ala Chef": "Ala Chef",
        "salata, piletina, pečurke, dimljeni sir, šunka, dresing":
          "salad, chicken, mushrooms, smoked cheese, ham, dressing",
        "Biftek salata": "Beef salad",
        "miks salata, dresing, biftek, mocarela, badem, krutoni, parmezan":
          "mixed salad, dressing, beef, mozzarella, almonds, croutons, parmesan",
        "Štrafta salata": "Štrafta salad",
        "miks salata, dresing, pohovana feta, hrskava piletina, indijski orah,krutoni, parmezan":
          "mixed salad, dressing, breaded feta, crispy chicken, cashews, croutons, parmesan",
        "Cezar salata": "Caesar salad",
        "miks salata, dresing, mocarela, piletina, slanina, krutoni,badem parmezan":
          "mixed salad, dressing, mozzarella, chicken, bacon, croutons, almonds, parmesan",
        "Losos salata": "Salmon salad",
        "miks salata, dresing, avokado, dimljeni losos, sjemenke bundeve":
          "mixed salad, dressing, avocado, smoked salmon, pumpkin seeds",
        "Caprese salata": "Caprese salad",
        "mocarela, paradajz, pesto sos, bosiljak":
          "mozzarella, tomato, pesto sauce, basil",
        "Sezonske salate": "Seasonal salads",
        "Šopska salata": "Šopska salad",
        "Miks salata": "Mixed salad",
        "Paradajz salata": "Tomato salad",
        "Sendviči i tortilje": "Sandwiches and Tortillas",
        "Sendvič Štrafta": "Sandwich Štrafta",
        "lepinja, šunka, topljeni sir, pesto sos, rukola, pomfrit":
          "bread, ham, melted cheese, pesto sauce, rocket, fries",
        "Sendvič Sicilija": "Sandwich Sicily",
        "Lepinja, namaz, pršuta, sir, salata, pomfrit":
          "bread, spread, prosciutto, cheese, salad, fries",
        "Pizza sendvič": "Pizza sandwich",
        "lepinja, namaz, pršuta, pomfrit": "bread, spread, prosciutto, fries",
        "Rich sendvič": "Rich sandwich",
        "lepinja, trapist, pohovana piletina, pomfrit":
          "bread, Emmental cheese, breaded chicken, fries",
        "Klub sendvič": "Club sandwich",
        "tost, piletina, jaje, šunka, trapist, pomfrit":
          "toast, chicken, egg, ham, Emmental cheese, fries",
        TORTILJE: "TORTILLAS",
        "Tortilja u cary sosu": "Tortilla in Cary sauce",
        "domaća tortilja, piletina, neutralna pavlaka, zelena salata paradajz, pomfrit":
          "homemade tortilla, chicken, sour cream, green salad, tomato, fries",
        "Tortilja u pesto sosu": "Tortilla in Pesto sauce",
        "domaća tortilja, sir, piletina, salata, pesto sos, pomfrit":
          "homemade tortilla, cheese, chicken, salad, pesto sauce, fries",
        "Tortilja piletina i gvakamole": "Tortilla chicken and guacamole",
        "domaća tortilja, gvakamole sos, piletina, salata, pomfrit":
          "homemade tortilla, guacamole sauce, chicken, salad, fries",
        "Tortilja tuna": "Tortilla tuna",
        "domaća tortilja, namaz, tunjevina, salata, pomfrit":
          "homemade tortilla, spread, tuna, salad, fries",
        Pizze: "Pizzas",
        "pelat, sir, pršuta, šunka, pančeta, kulen, pečurke, kajmak":
          "tomato sauce, cheese, prosciutto, ham, bacon, sausage, mushrooms, kajmak",
        Margarita: "Margarita",
        "sir, pelat, bosiljak": "cheese, tomato sauce, basil",
        Capricosa: "Capricciosa",
        "pelat, sir, šunka, pečurke": "tomato sauce, cheese, ham, mushrooms",
        Pepperoni: "Pepperoni",
        "pelat, sir, kulen, ljuta paprika":
          "tomato sauce, cheese, pepperoni, hot peppers",
        Tonno: "Tonno",
        "pelat, sir, tunjevina, luk": "tomato sauce, cheese, tuna, onion",
        Toscana: "Toscana",
        "pelat, sir, pršuta, gorgonzola, rukola":
          "tomato sauce, cheese, prosciutto, gorgonzola cheese, arugula",
        "Quatro formagi": "Quattro Formaggi",
        "pelat, gauda, gorgonzola, mocarela, parmeza":
          "tomato sauce, gouda, gorgonzola, mozzarella, parmesan",
        Vegeteriana: "Vegetarian",
        "pelat, sir, povrće": "tomato sauce, cheese, vegetables",
        Calcone: "Calzone",
        Pasta: "Pasta",
        Rižoto: "Risotto",
        "Paste i rižota": "Pasta and Risotto",
        "Ala Chef pasta": "Ala Chef Pasta",
        "piletina, pečurke,tikvice, neutralna pavlaka":
          "chicken, mushrooms, zucchini, neutral cream",
        "Pasta ai frutti di mare": "Pasta with seafood",
        "morski plodovi, pasta, crveni sos": "seafood, pasta, red sauce",
        "Arabiata pasta": "Arrabbiata Pasta",
        "pasta, paprika, pelat, čili": "pasta, bell pepper, tomato, chili",
        "Tagliatelle carbonara": "Tagliatelle Carbonara",
        "slanina, pavlaka neutralna, parmezan, jaje":
          "bacon, neutral cream, parmesan, egg",
        "Spaghetti Pollo e Pesto": "Spaghetti Pollo e Pesto",
        "piletina, neutralna pavlaka, pesto sos, indijski orah":
          "chicken, neutral cream, pesto sauce, cashews",
        "Njoke Formaggio e Pollo": "Gnocchi Formaggio e Pollo",
        "piletina, 4 vrste sira, badem, njoke":
          "chicken, 4 types of cheese, almonds, gnocchi",
        "Farfalle Vittelo e Fungi": "Farfalle Vittelo e Fungi",
        "biftek, neutralna pavlaka, pečurke, soya sos, parmezan":
          "beef, neutral cream, mushrooms, soy sauce, parmesan",
        "Tagliatelle  di tacchino arrosto": "Roasted Turkey Tagliatelle",
        "pečurke, piletina, neutralna pavlaka, mocarela":
          "mushrooms, chicken, neutral cream, mozzarella",
        "Risotto Formagio e Pollo": "Risotto Formaggio e Pollo",
        "piletina, 4 vrste sira, neutralna pavlaka, parmezan":
          "chicken, 4 types of cheese, neutral cream, parmesan",
        "Risotto tacchino e Pesto": "Risotto Turkey e Pesto",
        "piletina, pesto sos, neutralna pavlaka, parmezan":
          "chicken, pesto sauce, neutral cream, parmesan",
        "Risotto Vittelo e Fung": "Risotto Vittelo e Fung",
        "biftek, sos od pečurki, neutralna pavlaka, riža":
          "beef, mushroom sauce, neutral cream, rice",
        "Risotto nero ai frutti di mare": "Black Risotto with seafood",
        "crnilo od sipe, morski plodovi, riža": "cuttlefish ink, seafood, rice",
        "Risotto Rosso ai frutti di mare": "Red Risotto with seafood",
        SALATE: "SALAD",
        "crveni sos, morski plodovi, riža": "red sauce, seafood, rice",
        Copyright: "Copyright © 2024 Pixelvizija | All rights reserved.",
      },
    },
  },
  lng: "sr",
  fallbackLng: "sr",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
