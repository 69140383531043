import { Route, Routes } from "react-router-dom";
import { HotDrinks } from "./Drinks/HotDrinks";
import { DrinksCard } from "./Drinks/drinksCard";
import HomeScreen from "./HomeScreen/homeScreen";
import { NonAlcoholicDrinks } from "./Drinks/nonAlcohlicDrinks";
import { AlcoholicDrinks } from "./Drinks/alcoholicDrinks";
import { Wine } from "./Drinks/wine";
import { useEffect, useState } from "react";
import i18n from "./translate/translate";
import { Breakfast } from "./Food/breakfast";
import FoodCard from "./Food/foodCard";
import { Starters } from "./Food/starters";
import { Soups } from "./Food/soups";
import { Salats } from "./Food/salats";
import { MainDishes } from "./Food/mainDishes";
import { Tortillas } from "./Food/tortillas";
import { Pizza } from "./Food/pizza";
import { PastaAndRisotto } from "./Food/pastaAndRisotto";
import { FishDishes } from "./Food/fishDishes";
import { SideDishes } from "./Food/sideDish";
import { Desserts } from "./Food/desserts";
import { Footer } from "./Drinks/styles";
import { useTranslation } from "react-i18next";
import ErrorScreen from "./ErrorPage/errorPage";

const StraftaDigitalMenu = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("sr");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const { t } = useTranslation();

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!isMobile) {
    return <ErrorScreen />;
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <HomeScreen
              selectedLanguage={selectedLanguage}
              changeLanguage={changeLanguage}
            />
          }
        />
        <Route path="/drinks" element={<DrinksCard />} />
        <Route path="/drinks/hot-drinks" element={<HotDrinks />} />
        <Route
          path="/drinks/non-alcoholic-beverages"
          element={<NonAlcoholicDrinks />}
        />
        <Route
          path="/drinks/alcoholic-beverages"
          element={<AlcoholicDrinks />}
        />
        <Route path="/drinks/wine-card" element={<Wine />} />
        <Route path="/food" element={<FoodCard />} />
        <Route path="/food/breakfast" element={<Breakfast />} />
        <Route path="/food/starters" element={<Starters />} />
        <Route path="/food/soups" element={<Soups />} />
        <Route path="/food/salats" element={<Salats />} />
        <Route path="/food/main-courses" element={<MainDishes />} />
        <Route path="/food/tortillas" element={<Tortillas />} />
        <Route path="/food/pizze" element={<Pizza />} />
        <Route path="/food/pasta-and-risoto" element={<PastaAndRisotto />} />
        <Route path="/food/fish-dishes" element={<FishDishes />} />
        <Route path="/food/prilozi" element={<SideDishes />} />
        <Route path="/food/dezerti" element={<Desserts />} />
      </Routes>
      <Footer>
        <a href="https://www.instagram.com/pixelvizija_/">{t("Copyright")}</a>
      </Footer>
    </>
  );
};

export default StraftaDigitalMenu;
