import React from "react";

const DessertIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={35}
      height={35}
      viewBox="0 0 64 64"
      fill="#b78b2c"
    >
      <g data-name="Layer 14">
        <path d="M41 8a.988.988 0 00-.482.124l-40 22a.968.968 0 00-.147.115 1.118 1.118 0 00-.093.074 1 1 0 00-.2.294c-.005.012-.005.026-.01.039a1.011 1.011 0 00-.064.318c0 .013-.007.023-.007.036v24a1 1 0 001 1H63a1 1 0 001-1V31A23.026 23.026 0 0041 8zm.254 2a21.028 21.028 0 0120.723 20h-2.211a11.112 11.112 0 00-5.442-6.651 11.293 11.293 0 00-11.069-8.857c-.318 0-.644.016-.984.049l-.011-.022a9.569 9.569 0 00-2.58-3.652zm10.79 16.7l-1.466-.694-.239-1.554a7.151 7.151 0 00-7.084-5.963 7.97 7.97 0 00-1.083.091l-2.282.335-1.005-2a7.283 7.283 0 00-3.837-3.329l2.942-1.471c1.224.722 1.759 1.815 2.473 3.274l.333.674a1 1 0 001.047.539 9.125 9.125 0 011.412-.118 9.257 9.257 0 019.185 7.676 1 1 0 00.56.752A9.171 9.171 0 0157.678 30H55.41a7.029 7.029 0 00-3.366-3.295zM32.148 15.01a5.839 5.839 0 014.952 2.812l1.328 2.642a1 1 0 001.039.54l2.984-.439a6.164 6.164 0 01.8-.071 5.138 5.138 0 015.107 4.265l.32 2.081a1 1 0 00.56.752l1.947.921A5.137 5.137 0 0153.038 30H4.894zM2 44h56v2H2zm56-2H2v-6h56zM2 54v-6h56v6zm60 0h-2V35a1 1 0 00-1-1H2v-2h60z"></path>
        <path d="M36 20.142V18a1 1 0 00-2 0v2.142a4 4 0 102 0zM35 26a1.994 1.994 0 01-1-3.722V23a1 1 0 002 0v-.722A1.994 1.994 0 0135 26z"></path>
      </g>
    </svg>
  );
};

export default DessertIcon;
