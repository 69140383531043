import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../icons/arrowIcon";
import { hotDrinkItems, nonAlcoholicDrinksItems } from "./constants";
import {
  Container,
  Description,
  Header,
  ItemContainer,
  Price,
  SectionTitleWrapper,
  Subtitle,
  TextWrapper,
  Title,
} from "./styles";

export const NonAlcoholicDrinks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const Items = hotDrinkItems.map((item) => {
    return (
      <>
        <ItemContainer isFirst={item?.isFirst}>
          <TextWrapper isDescription={true}>
            <span style={{ display: "flex", alignItems: "baseline" }}>
              <Title>{t(item.title)}</Title>
              <Subtitle>{item?.subtitle}</Subtitle>
            </span>
            {item.description && (
              <Description>{t(item.description)}</Description>
            )}
          </TextWrapper>
          <div>
            <Price>{item.price}</Price>
          </div>
        </ItemContainer>
      </>
    );
  });

  const NonAlcoholicDrinks = nonAlcoholicDrinksItems.map((item) => {
    return (
      <ItemContainer isFirst={item?.isFirst}>
        <TextWrapper>
          <Title>{t(item.title)}</Title>
          <Subtitle>{item?.subtitle}</Subtitle>
        </TextWrapper>
        <div>
          <Price>{item.price}</Price>
        </div>
      </ItemContainer>
    );
  });
  return (
    <>
      <Header>
        <span
          onClick={() => navigate("/drinks")}
          style={{ display: "flex", alignItems: "center" }}
        >
          <ArrowIcon />
          <p>{t("BEZALKOHOLNA PIĆA")}</p>
        </span>
      </Header>

      <Container>
        <SectionTitleWrapper>
          <p>{t("Prirodni sokovi")}</p>
        </SectionTitleWrapper>
        {Items}
        <SectionTitleWrapper>
          <p>{t("Bezalkoholna pića")}</p>
        </SectionTitleWrapper>
        {NonAlcoholicDrinks}
        <SectionTitleWrapper>
          <p>{t("Vode")}</p>
        </SectionTitleWrapper>
        <ItemContainer isFirst={true}>
          <TextWrapper>
            <Title>{t("Rosa")}</Title>
            <Subtitle>0,7</Subtitle>
          </TextWrapper>
          <div>
            <Price>3,00€</Price>
          </div>
        </ItemContainer>
        <ItemContainer isFirst={false}>
          <TextWrapper>
            <Title>{t("Kisjela")}</Title>
            <Subtitle>0,7</Subtitle>
          </TextWrapper>
          <div>
            <Price>3,00€</Price>
          </div>
        </ItemContainer>
        <SectionTitleWrapper>
          <p>{t("Energetska pića")}</p>
        </SectionTitleWrapper>
        <ItemContainer isFirst={true}>
          <TextWrapper>
            <Title>{t("Red bull")}</Title>
            <Subtitle>0,25</Subtitle>
          </TextWrapper>
          <div>
            <Price>3,50€</Price>
          </div>
        </ItemContainer>
        <ItemContainer isFirst={false}>
          <TextWrapper>
            <Title>{t("Guarana")}</Title>
            <Subtitle>0,25</Subtitle>
          </TextWrapper>
          <div>
            <Price>2,50€</Price>
          </div>
        </ItemContainer>
        <SectionTitleWrapper>
          <p>{t("Šejk")}</p>
        </SectionTitleWrapper>
        <ItemContainer isFirst={true}>
          <TextWrapper>
            <Title>{t("Banana")}</Title>
            <Subtitle>0,30</Subtitle>
          </TextWrapper>
          <div>
            <Price>2,50€</Price>
          </div>
        </ItemContainer>
        <ItemContainer isFirst={false}>
          <TextWrapper>
            <Title>{t("Plazma")}</Title>
            <Subtitle>0,30</Subtitle>
          </TextWrapper>
          <div>
            <Price>3,00€</Price>
          </div>
        </ItemContainer>
      </Container>
    </>
  );
};
