import React from "react";

const PastaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#b78b2c"
      width={45}
      height={45}
      enableBackground="new 0 0 3873 3873"
      viewBox="0 0 3873 4841.25"
    >
      <path d="M2234.1 3132.7c-13.7-30.5-.2-66.3 30.3-80 440.7-198 725.4-623.5 725.4-1083.8v-77.7H446.3v77.5c0 460.3 284.7 885.8 725.4 1083.8 30.5 13.7 44.1 49.5 30.4 80-13.7 30.5-49.5 44.1-80 30.4C497.7 2882 402 2470.8 325.3 1830.6c0-33.4 27.1-60.5 60.5-60.5h2664.6c33.4 0 60.5 27.1 60.5 60.5v138.1c0 521.9-320.7 980.2-796.8 1194.3-30.4 13.6-66.3.1-80-30.3z"></path>
      <path d="M3050.4 2095.3H387.8c-33.4 0-60.5-27.1-60.5-60.5s27.1-60.5 60.5-60.5h2662.7c33.4 0 60.5 27.1 60.5 60.5s-27.1 60.5-60.6 60.5zM2155.9 3465.3h-875.4c-107 0-194.1-73.5-194.1-163.8v-193.6c0-33.4 27.1-60.5 60.5-60.5h1142.2c33.4 0 60.5 27.1 60.5 60.5v193.6c.3 90.3-86.8 163.8-193.7 163.8zm-948.5-296.9v133.1c0 20.2 31.2 42.8 73.1 42.8h875.4c41.8 0 73.1-22.7 73.1-42.8v-133.1H1207.4zM1718.1 1891.1c-33.4 0-60.5-27.1-60.5-60.5 3.5-295.4-233.1-537.8-528.6-541.3s-537.8 233.1-541.3 528.6c-.1 4.2-.1 8.5 0 12.7 0 33.4-27.1 60.5-60.5 60.5s-60.5-27.1-60.5-60.5c-3.9-362.3 286.6-659.1 648.9-663 362.3-3.9 659.1 286.6 663 648.9.1 4.7.1 9.4 0 14.1 0 33.4-27.1 60.5-60.5 60.5z"></path>
      <path d="M1518.3 1891.1c-33.4 0-60.5-27.1-60.5-60.5 2.8-185.1-145-337.3-330-340.1s-337.3 145-340.1 330c-.1 3.4-.1 6.7 0 10.1 0 33.4-27.1 60.5-60.5 60.5s-60.5-27.1-60.5-60.5c-3.2-251.9 198.3-458.7 450.2-462 251.9-3.2 458.7 198.3 462 450.2.1 3.9.1 7.8 0 11.7-.1 33.5-27.2 60.6-60.6 60.6z"></path>
      <path d="M1318.4 1891.1c-33.4 0-60.5-27.1-60.5-60.5 1.8-74.7-57.3-136.6-132-138.4s-136.6 57.3-138.4 132c-.1 2.1-.1 4.3 0 6.4 0 33.4-27.1 60.5-60.5 60.5s-60.5-27.1-60.5-60.5c-2.4-141.5 110.3-258.2 251.8-260.6s258.2 110.3 260.6 251.8c.1 2.9.1 5.9 0 8.8 0 33.4-27.1 60.5-60.5 60.5zM2909 1891.1c-33.4 0-60.5-27.1-60.5-60.5 0-295.4-239.5-535-535-535s-535 239.5-535 535c0 33.4-27.1 60.5-60.5 60.5s-60.5-27.1-60.5-60.5c0-362.3 293.7-656 656-656s656 293.7 656 656c.1 33.4-27 60.5-60.5 60.5z"></path>
      <path d="M2709.1 1891.1c-33.4 0-60.5-27.1-60.5-60.5 2.8-185.1-145-337.3-330-340.1-185.1-2.8-337.3 145-340.1 330-.1 3.4-.1 6.7 0 10.1 0 33.4-27.1 60.5-60.5 60.5s-60.5-27.1-60.5-60.5c-3.2-251.9 198.3-458.7 450.2-462 251.9-3.2 458.7 198.3 462 450.2.1 3.9.1 7.8 0 11.7-.1 33.5-27.2 60.6-60.6 60.6z"></path>
      <path d="M2509.3 1891.1c-33.4 0-60.5-27.1-60.5-60.5 2.5-74.6-56-137.1-130.6-139.6s-137.1 56-139.6 130.6c-.1 3-.1 6 0 9 0 33.4-27.1 60.5-60.5 60.5s-60.5-27.1-60.5-60.5c-.1-141.5 114.6-256.3 256.1-256.3 141.5-.1 256.3 114.6 256.3 256.1v.3c0 33.4-27.1 60.5-60.5 60.5-.1-.1-.2-.1-.2-.1zM2233 1252.1c-80.4-284.4-376.2-449.7-660.5-369.3-178.9 50.6-318.7 190.4-369.3 369.3-9.7 32-43.4 50.1-75.4 40.5-31.1-9.4-49.3-41.7-41.1-73.2 98.6-348.7 461.1-551.5 809.9-452.9 219.4 62 390.9 233.5 452.9 452.9 8.4 32.3-10.9 65.4-43.3 73.8-31.4 8.2-63.8-10-73.2-41.1z"></path>
      <path d="M2037.2 1295.2c-56.6-176.3-245.3-273.3-421.6-216.7-103 33-183.7 113.7-216.7 216.7-10.2 31.8-44.3 49.4-76.1 39.2-31.8-10.2-49.4-44.3-39.2-76.1 76.9-239.9 333.8-372.1 573.7-295.1 140.2 45 250.2 154.9 295.1 295.1 10.2 31.8-7.3 65.9-39.2 76.1-31.7 10.2-65.8-7.4-76-39.2z"></path>
      <path d="M1853.3 1391.9c-3.1-74.6-66-132.6-140.6-129.5-70.3 2.9-126.6 59.2-129.5 129.5-1.3 33.4-29.5 59.4-62.9 58.1-33.4-1.3-59.4-29.5-58.1-62.9 6-141.4 125.4-251.2 266.8-245.2 133 5.6 239.6 112.2 245.2 245.2 1.3 33.4-24.7 61.6-58.1 62.9-33.4 1.3-61.6-24.7-62.9-58.1h.1zM1022.9 2724.3c-257.2-101-371.2-295-381.2-504.5-1.6-33.4 24.2-61.8 57.6-63.4 33.4-1.6 61.8 24.2 63.4 57.6 7.6 158.9 90.8 313.8 304.5 397.7 31.3 11.7 47.2 46.6 35.4 77.9-11.7 31.3-46.6 47.2-77.9 35.4-.6-.3-1.2-.5-1.8-.7zM3373.7 970.1c-38.2 0-21.5-6.1-394.5-301.7-26-21-30-59.1-9-85.1l85.3-105.4c65.6-81.3 184.7-94 265.9-28.3.1.1.2.1.3.2l156.1 126.3c81.2 65.8 93.8 184.9 28.2 266.2l-85.2 105.4c-11.4 14.4-28.7 22.7-47.1 22.7v-.3zM3102 612.7l262.3 211.8 47.1-58.3c23.7-29.2 19.3-72-9.9-95.8-.1-.1-.2-.1-.3-.2L3245 543.9c-29.2-23.7-72.1-19.3-95.8 9.9-.1.1-.1.2-.2.3l-47 58.6z"></path>
      <path d="M2459.9 1313.9c-33.4 0-60.5-27.1-60.5-60.5 0-15.5 6-30.5 16.7-41.8 190.3-199.2 4.5-11 609.2-593.2 23.4-23.9 61.7-24.3 85.6-.9 23.9 23.4 24.3 61.7.9 85.6-.9.9-1.9 1.9-2.9 2.7-651.7 634.8-610.9 608.1-649 608.1zM2840.7 1614.2c-33.4 0-60.5-27.2-60.5-60.6 0-12.2 3.7-24 10.5-34.1l16.2-23.8c145.2-213.3 176.3-266 466.9-662.4 19.6-27.2 57.5-33.3 84.6-13.7 27.2 19.6 33.3 57.5 13.7 84.6l-481.4 683.4c-11.2 16.7-29.9 26.7-50 26.6z"></path>
    </svg>
  );
};

export default PastaIcon;
