import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../icons/arrowIcon";
import { alcoholicDrinksItems } from "./constants";
import {
  Container,
  Header,
  ItemContainer,
  Price,
  SectionTitleWrapper,
  Subtitle,
  TextWrapper,
  Title,
} from "./styles";

export const AlcoholicDrinks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const alcoholicDrinks = alcoholicDrinksItems.map((item) => {
    return (
      <>
        {item.section && (
          <SectionTitleWrapper>
            <p>{t(item.section)}</p>
          </SectionTitleWrapper>
        )}
        {item?.title && (
          <ItemContainer isFirst={item?.isFirst}>
            <TextWrapper>
              <Title>{item?.title && t(item?.title)}</Title>
              <Subtitle>{item?.subtitle}</Subtitle>
            </TextWrapper>
            <div>
              <Price>{item.price}</Price>
            </div>
          </ItemContainer>
        )}
      </>
    );
  });

  return (
    <>
      <Header>
        <span
          onClick={() => navigate("/drinks")}
          style={{ display: "flex", alignItems: "center" }}
        >
          <ArrowIcon />
          <p>{t("ALKOHOLNA PIĆA")}</p>
        </span>
      </Header>
      <Container>{alcoholicDrinks}</Container>
    </>
  );
};
