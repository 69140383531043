import React from "react";

const WineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      fill="#b78b2c"
      width={30}
      height={30}
    >
      <path d="M40.06 62.02H23.94a2.57 2.57 0 01-2.57-2.57V30.47c0-3.89 2.16-7.49 5.55-9.34l.72-13.81c.03-.53.46-.94 1.02-.92.53.03.94.48.92 1.02l-.75 14.37c-.02.36-.23.67-.55.82a8.748 8.748 0 00-4.97 7.86v28.98c0 .35.28.63.63.63h16.13c.35 0 .63-.28.63-.63V30.47c0-2.32-.9-4.5-2.55-6.15a8.858 8.858 0 00-2.43-1.72.969.969 0 01-.55-.82l-.76-14.36c-.03-.53.38-.99.92-1.02.51-.03.99.38 1.02.92l.73 13.81c.9.49 1.71 1.1 2.44 1.83 2.01 2.01 3.12 4.68 3.12 7.52v28.98a2.582 2.582 0 01-2.58 2.56z"></path>
      <path d="M36.14 8.34h-8.28c-.27 0-.53-.11-.72-.32a1 1 0 01-.25-.74l.4-4.42c.04-.5.46-.88.97-.88h7.48c.5 0 .92.38.97.88l.4 4.42a1 1 0 01-.25.74c-.19.21-.45.32-.72.32zM28.92 6.4h6.16l-.22-2.48h-5.71l-.23 2.48zm12.74 50.04H29.55c-.54 0-.97-.43-.97-.97V34.46c0-.54.43-.97.97-.97h12.11c.54 0 .97.43.97.97s-.43.97-.97.97H30.52V54.5h11.14c.54 0 .97.43.97.97s-.43.97-.97.97zM9.39 51.53C4.72 51.53 0 48.33 0 41.17c0-2.46.59-5.11 1.67-7.47.16-.35.5-.57.88-.57h13.68c.38 0 .72.22.88.57 1.08 2.35 1.67 5.01 1.67 7.47 0 7.16-4.71 10.36-9.39 10.36zm-6.2-16.45c-.81 1.96-1.25 4.1-1.25 6.09 0 6.22 4.02 8.42 7.45 8.42s7.45-2.21 7.45-8.42c0-1.99-.44-4.13-1.25-6.09H3.19zm9.58 26.94H6.02c-.54 0-.97-.43-.97-.97s.43-.97.97-.97h6.75c.54 0 .97.43.97.97 0 .53-.44.97-.97.97z"></path>
      <path d="M12.57 41.87c-2.05 0-3.69-.45-5.21-.87-1.99-.55-3.7-1.02-6.07-.43a.976.976 0 01-1.18-.71.976.976 0 01.71-1.18c2.87-.72 5-.13 7.06.44 2.52.69 5.13 1.41 9.59-.08.51-.17 1.06.1 1.23.61.17.51-.1 1.06-.61 1.23-2.18.73-3.97.99-5.52.99zM9.39 62.02c-.54 0-.97-.43-.97-.97V50.56c0-.54.43-.97.97-.97s.97.43.97.97v10.49c0 .53-.43.97-.97.97zM54.61 51.53c-4.68 0-9.39-3.2-9.39-10.36 0-2.46.59-5.11 1.67-7.47.16-.35.5-.57.88-.57h13.68c.38 0 .72.22.88.57 1.08 2.35 1.67 5.01 1.67 7.47 0 7.16-4.72 10.36-9.39 10.36zM48.4 35.08c-.81 1.96-1.25 4.1-1.25 6.09 0 6.22 4.02 8.42 7.45 8.42s7.45-2.21 7.45-8.42c0-1.99-.44-4.13-1.25-6.09H48.4zm9.58 26.94h-6.75c-.54 0-.97-.43-.97-.97s.43-.97.97-.97h6.75a.97.97 0 010 1.94z"></path>
      <path d="M57.79 41.87c-2.05 0-3.69-.45-5.21-.87-1.99-.55-3.7-1.02-6.07-.43a.976.976 0 01-1.18-.71.976.976 0 01.71-1.18c2.87-.72 5-.13 7.06.44 2.52.69 5.13 1.41 9.59-.08.51-.17 1.06.1 1.23.61.17.51-.1 1.06-.61 1.23-2.18.73-3.97.99-5.52.99zm-3.18 20.15c-.54 0-.97-.43-.97-.97V50.56c0-.54.43-.97.97-.97s.97.43.97.97v10.49c0 .53-.44.97-.97.97z"></path>
    </svg>
  );
};

export default WineIcon;
