import React from "react";

const MainCourseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Line"
      viewBox="0 0 64 80"
      fill="#b78b2c"
      width={45}
      height={45}
    >
      <path d="M56.47 37.588h-1.518a28.127 28.127 0 00-2.895-10.7l.428-.337a3.823 3.823 0 005.563-4.828 4.01 4.01 0 00-2.437-1.993 3.927 3.927 0 00-1.385-2.809 3.819 3.819 0 00-5.96 4.317l-.538.43c-2.057-1.749-4.541-2.422-7.087-3.23a50.132 50.132 0 01-6.349-3.278c-10.314-6.27-23.458.355-25.698 14.053a31.335 31.335 0 00-.098 8.375h-.948a3.678 3.678 0 000 7.35h1.104l.46 2.002a5.178 5.178 0 004.856 4.278H49.51a5.181 5.181 0 004.865-4.275l.462-2.004h1.634c4.699-.096 4.712-7.253 0-7.351zm-3.527 0h-6.11a15.375 15.375 0 00.57-4.617 8.26 8.26 0 00-.131-2.317l3.19-2.512a26.552 26.552 0 012.481 9.446zm-2.836-15.262a1 1 0 00.264-1.236 2.292 2.292 0 01-.222-1.897 1.82 1.82 0 012.802-.73c1.165 1.045.277 1.95.893 2.73.623.715 1.488-.049 2.414 1.423a1.796 1.796 0 01-.053 1.647 1.908 1.908 0 01-3.099.273 1.002 1.002 0 00-1.267-.023l-5.316 4.184A6.427 6.427 0 0044.64 26.7zm-39.539 7.211c1.568-11.216 12.933-18.604 22.703-12.657a52.191 52.191 0 006.612 3.408c2.238.789 4.384 1.186 6.245 2.66l-3.44 2.752a9.28 9.28 0 00-2.369-.554c-.7-.2-1.524.054-1.577.866-.023.853.657 1.084 1.381 1.123 2.802.24 5.763 2.218 5.28 5.746a13.567 13.567 0 01-.65 4.707H20.44a11.297 11.297 0 014.523-6.18 23.424 23.424 0 0110.88-4.376 1 1 0 10-.206-1.99 25.037 25.037 0 00-11.894 4.783 12.974 12.974 0 00-5.356 7.763h-7.862a29.115 29.115 0 01.043-8.05zM58.011 41.4a1.541 1.541 0 01-1.54 1.54H18.008a1 1 0 000 2h34.776l-.36 1.557a3.206 3.206 0 01-2.914 2.722H13.968a3.203 3.203 0 01-2.905-2.724l-.359-1.555h3.304a1 1 0 000-2h-6.46c-2.048.006-2.063-3.356 0-3.351H56.47a1.584 1.584 0 011.54 1.81z"></path>
      <path d="M14.484 29.853a1.003 1.003 0 001.218-.72c1.545-5.487 6.164-9.711 11.184-9.677a1 1 0 001-1c-.084-1.433-1.862-.951-2.812-.854-4.23.69-7.66 3.406-10.196 8.073a12.96 12.96 0 00-1.114 2.96 1.002 1.002 0 00.72 1.218z"></path>
    </svg>
  );
};

export default MainCourseIcon;
