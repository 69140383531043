export const hotDrinkItems = [
  {
    title: "Limunada",
    subtitle: "0,33",
    price: "2,00€",
    isFirst: true,
  },
  {
    title: "Cijeđena pomorandža",
    subtitle: "0,33",
    price: "3,00€",
  },
  {
    title: "Mix",
    subtitle: "",
    price: "3,00€",
  },
  {
    title: "Green",
    subtitle: "0,2",
    price: "3,00€",
    description: "detox",
  },
];

export const nonAlcoholicDrinksItems = [
  {
    title: "Coca cola",
    subtitle: "0,33",
    price: "2,40€",
    isFirst: true,
  },
  {
    title: "Coca cola zero",
    subtitle: "0,33",
    price: "2,40€",
  },
  {
    title: "Fanta",
    subtitle: "0,33",
    price: "2,40€",
  },
  {
    title: "Sprite",
    subtitle: "0,33",
    price: "2,40€",
  },
  {
    title: "Biter lemon",
    subtitle: "0,25",
    price: "2,40€",
  },
  {
    title: "Tonic",
    subtitle: "0,25",
    price: "2,40€",
  },
  {
    title: "Ledeni čaj",
    subtitle: "0,33",
    price: "2,40€",
  },
  {
    title: "Cedevita",
    subtitle: "0,25",
    price: "2,40€",
  },
  {
    title: "Rosa",
    subtitle: "0,33",
    price: "1,50€",
  },
  {
    title: "Kisjela",
    subtitle: "0,33",
    price: "1,50€",
  },
];

export const alcoholicDrinksItems = [
  { section: "PIVA" },
  { title: "Nikšićko", subtitle: "0,33", price: "2,80€", isFirst: true },
  { title: "Corona", subtitle: "0,35", price: "4,00€" },
  { title: "Bavaria", subtitle: "0,25", price: "2,80€" },
  { title: "Stela artois", subtitle: "0,33", price: "3,00€" },
  { title: "Somersby", subtitle: "0,33", price: "3,20€" },
  { section: "Vodke" },
  { title: "Smirnoff", subtitle: "0,03", price: "2,20€", isFirst: true },
  { title: "Grey Goose", subtitle: "0,03", price: "4,50€" },
  { title: "Belvedere", subtitle: "0,03", price: "4,20€" },
  { title: "Smirnoff Ice", subtitle: "0,03", price: "3,20€" },
  { section: "Rakije" },
  { title: "Šamar", subtitle: "0,03", price: "2,50€", isFirst: true },
  { title: "Meduška", subtitle: "0,03", price: "2,00€" },
  { title: "Loza institut", subtitle: "0,03", price: "2,20€" },
  { title: "Viljamovka", subtitle: "0,03", price: "2,50€" },
  { title: "Dunja", subtitle: "0,03", price: "2,50€" },
  { title: "Kajsija", subtitle: "0,03", price: "2,50€" },
  { title: "Premium šljiva", subtitle: "0,03", price: "2,50€" },
  { section: "Žestoka pića" },
  { title: "Vinjak", subtitle: "0,03", price: "1,60€", isFirst: true },
  { title: "Štok", subtitle: "0,03", price: "1,80€" },
  { title: "Gin", subtitle: "0,03", price: "2,20€" },
  { title: "Tekila", subtitle: "0,03", price: "2,20€" },
  { section: "Konjak" },
  { title: "Courvoisier", subtitle: "0,03", price: "6,00€", isFirst: true },
  { title: "Hennesy V.S.O.P", subtitle: "0,03", price: "6,00€" },
  { section: "Burbon" },
  { title: "Jim Beam White", subtitle: "0,03", price: "2,00€", isFirst: true },
  { title: "Jim Beam Black", subtitle: "0,03", price: "2,30€" },
  { title: "Jack Daniels", subtitle: "0,03", price: "3,20€" },
  { title: "Jack Daniels single barel", subtitle: "0,03", price: "4,60€" },
  { section: "Viski" },
  { title: "Jameson", subtitle: "0,03", price: "2,50€", isFirst: true },
  { title: "Johnny walker red", subtitle: "0,03", price: "2,50€" },
  { title: "Johnny walker black", subtitle: "0,03", price: "3,50€" },
  { title: "Chivas", subtitle: "0,03", price: "3,50€" },
  { title: "Chivas Regal /18 y.o ", subtitle: "0,03", price: "5,00€" },
  { title: "Ballantine's", subtitle: "0,03", price: "2,30€" },
  { section: "Likeri" },
  { title: "Jagermeister", subtitle: "0,03", price: "2,70€", isFirst: true },
  { title: "Martini", subtitle: "0,03", price: "2,50€" },
  { title: "Baileys", subtitle: "0,03", price: "2,40€" },
  { title: "Amaro Montenegro", subtitle: "0,03", price: "2,40€" },
  { title: "Pelinkovac", subtitle: "0,03", price: "2,00€" },
  { title: "Aperol", subtitle: "0,03", price: "6,00€" },
  { section: "Šampanjac" },
  { title: "Trockenbau", subtitle: "0,7", price: "18,00€", isFirst: true },
  { title: "Prosecco", subtitle: "0,7", price: "24,00€" },
  { title: "Moet", subtitle: "0,75", price: "120,00€" },
];

export const wineItems = [
  { section: "Bijela vina" },
  { title: "Chardonny", subtitle: "0,75", price: "15,00€", isFirst: true },
  { title: "Chardonny Barrique", subtitle: "0,75", price: "26,00€" },
  { title: "Chardonny  Kovačević", subtitle: "0,75", price: "25,00€" },
  { title: "Pinot Grigio", subtitle: "0,75", price: "25,00€" },
  { title: "Chardonny Radovanović", subtitle: "0,75", price: "25,00€" },
  { title: "Chardonny Plantaže", subtitle: "0,18", price: "3,80€" },
  { title: "Malvazija", subtitle: "0,18", price: "4,50€" },
  { section: "Crna vina" },
  {
    title: "Vranac Pro Corde",
    subtitle: "0,75",
    price: "15,00€",
    isFirst: true,
  },
  { title: "Epoha", subtitle: "0,75", price: "18,00€" },
  { title: "Vladika", subtitle: "0,75", price: "25,00€" },
  { title: "Vranac Barrique", subtitle: "0,75", price: "26,00€" },
  { title: "Vranac Reserve", subtitle: "0,75", price: "50,00€" },
  { title: "Stari Podrum", subtitle: "0,75", price: "100,00€" },
  { title: "Premijer", subtitle: "0,75", price: "100,00€" },
  { title: "Pro Corde", subtitle: "0,18", price: "4,50€" },
  { title: "Vranac", subtitle: "0,18", price: "3,80€" },
  { title: "Kupinovo vino", subtitle: "0,18", price: "3,80€" },
  { section: "Rose vina" },
  {
    title: "Crnogorski Roze",
    subtitle: "0,75",
    price: "13,00€",
    isFirst: true,
  },
  { title: "Radovanović Roze", subtitle: "0,75", price: "20,00€" },
  { title: "Roze Val", subtitle: "0,75", price: "24,00€" },
  { title: "Roze Plantaže", subtitle: "0,18", price: "3,80€" },
];
