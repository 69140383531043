import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../icons/arrowIcon";
import { tortillasItems } from "./constants";
import {
  Container,
  Description,
  Header,
  ItemContainer,
  Price,
  SectionTitleWrapper,
  Subtitle,
  TextWrapper,
  Title,
} from "./styles";

export const Tortillas = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tortillas = tortillasItems.map((item) => {
    return (
      <>
        {item.section && (
          <SectionTitleWrapper>
            <p>{t(item.section)}</p>
          </SectionTitleWrapper>
        )}
        {item?.title && (
          <ItemContainer isFirst={item?.isFirst}>
            <TextWrapper isDescription={true}>
              <span style={{ display: "flex", alignItems: "baseline" }}>
                <Title>{t(item.title)}</Title>
                <Subtitle>{item?.subtitle}</Subtitle>
              </span>
              {item?.description && (
                <Description>({t(item.description)})</Description>
              )}
            </TextWrapper>
            <div>
              <Price>{item.price}</Price>
            </div>
          </ItemContainer>
        )}
      </>
    );
  });

  return (
    <>
      <Header>
        <span
          onClick={() => navigate("/food")}
          style={{ display: "flex", alignItems: "center" }}
        >
          <ArrowIcon />
          <p>{t("Sendviči i tortilje")}</p>
        </span>
      </Header>
      <Container>{tortillas}</Container>
    </>
  );
};
