import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AlcoholIcon from "../icons/alcoholIcon";
import ArrowIcon from "../icons/arrowIcon";
import CoffeeIcon from "../icons/coffeeIcon";
import WineIcon from "../icons/wineIcon";
import {
  Header,
  IconWrapper,
  Image,
  ImageWrapper,
  ItemWrapper,
  MenuItemsContainer,
  Text,
  Wrapper,
} from "./styles";

export const DrinksCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const DrinkItems = [
    {
      icon: <CoffeeIcon />,
      title: "TOPLI NAPICI",
      navigate: "/drinks/hot-drinks",
    },
    {
      image: "/straftaPhotos/bezalkoholnaPica.png",
      title: "BEZALKOHOLNA PIĆA",
      navigate: "/drinks/non-alcoholic-beverages",
    },
    {
      icon: <AlcoholIcon />,
      title: "ALKOHOLNA PIĆA",
      navigate: "/drinks/alcoholic-beverages",
    },
    {
      icon: <WineIcon />,
      title: "VINSKA KARTA",
      navigate: "/drinks/wine-card",
    },
  ];

  const Items = DrinkItems.map((item) => {
    return (
      <ItemWrapper onClick={() => navigate(item.navigate)}>
        <ImageWrapper>
          {item.icon && <IconWrapper>{item.icon}</IconWrapper>}
          {item.image && <Image src={item.image} alt="error" width={30} />}
        </ImageWrapper>
        <Text>{t(item.title)}</Text>
      </ItemWrapper>
    );
  });

  return (
    <>
      <Header>
        <span onClick={() => navigate("/")} style={{ padding: "5px" }}>
          <ArrowIcon />
        </span>
      </Header>
      <Wrapper>
        <img
          src="/straftaPhotos/Strafta.png"
          alt="not found"
          width={200}
          style={{ alignSelf: "center" }}
          loading="lazy"
        />
        <MenuItemsContainer>{Items}</MenuItemsContainer>
      </Wrapper>
    </>
  );
};

export default DrinksCard;
