import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0a1e1c;
  margin-top: auto;
  height: 100vh; /* Da popuni celu visinu ekrana */
`;

export const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.p`
  font-size: 12px;
  color: #b78b2c;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
`;

export const Footer = styled.footer`
  width: 100%;
  background-color: #0b1f1c;
  color: #b78b2c;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding: 20px 0;

  a {
    font-size: 10px;
    margin: 0;
    text-decoration: none;
    color: #b78b2c;
  }
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;

  p {
    text-transform: uppercase;
    color: #b78b2c;
    padding-top: 35px;
    margin-top: 20px;
  }
`;
