import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../icons/arrowIcon";
import BreacfastIcon from "../icons/bracfastIcon";
import DessertIcon from "../icons/dessertIcon";
import FishIcon from "../icons/fishIcon";
import FriesIcon from "../icons/friesIcon";
import MainCourseIcon from "../icons/mainCourseIcon";
import PastaIcon from "../icons/pastaIcon";
import PizzaIcon from "../icons/pizzaIcon";
import SaladIcon from "../icons/saladIcon";
import SandwichIcon from "../icons/sandwicheIcon";
import SoupIcon from "../icons/sopuIcon";
import StartersIcon from "../icons/startersIcon";
import {
  Header,
  IconWrapper,
  ImageWrapper,
  ItemWrapper,
  MenuItemsContainer,
  Text,
  Wrapper,
} from "./styles";

export const FoodCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const DrinkItems = [
    {
      icon: <BreacfastIcon />,
      title: "Doručak",
      navigate: "/food/breakfast",
    },
    {
      icon: <StartersIcon />,
      title: "Starters",
      navigate: "/food/starters",
    },
    {
      icon: <SoupIcon />,
      title: "Potaž i supe",
      navigate: "/food/soups",
    },
    {
      icon: <MainCourseIcon />,
      title: "Glavna jela",
      navigate: "/food/main-courses",
    },
    {
      icon: <SaladIcon />,
      title: "SALATE",
      navigate: "/food/salats",
    },
    {
      icon: <SandwichIcon />,
      title: "Sendviči i tortilje",
      navigate: "/food/tortillas",
    },
    {
      icon: <PizzaIcon />,
      title: "Pizze",
      navigate: "/food/pizze",
    },
    {
      icon: <PastaIcon />,
      title: "Paste i rižota",
      navigate: "/food/pasta-and-risoto",
    },
    {
      icon: <FishIcon />,
      title: "Jela od ribe",
      navigate: "/food/fish-dishes",
    },
    {
      icon: <FriesIcon />,
      title: "Prilozi",
      navigate: "/food/prilozi",
    },
    {
      icon: <DessertIcon />,
      title: "Dezerti",
      navigate: "/food/dezerti",
    },
  ];

  const Items = DrinkItems.map((item) => {
    return (
      <ItemWrapper onClick={() => navigate(item.navigate)}>
        <ImageWrapper>
          {item.icon && <IconWrapper>{item.icon}</IconWrapper>}
        </ImageWrapper>
        <Text>{t(item.title)}</Text>
      </ItemWrapper>
    );
  });

  return (
    <>
      <Header>
        <span onClick={() => navigate("/")} style={{ padding: "5px" }}>
          <ArrowIcon />
        </span>
      </Header>
      <Wrapper>
        <img
          src="/straftaPhotos/Strafta.png"
          alt="not found"
          width={200}
          style={{ alignSelf: "center" }}
          loading="lazy"
        />
        <MenuItemsContainer>{Items}</MenuItemsContainer>
      </Wrapper>
    </>
  );
};

export default FoodCard;
