import React from "react";

const AlcoholIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
      fill="#b78b2c"
      width={45}
      height={45}
    >
      <path d="M63 49.496H1a1 1 0 01-1-1v-4a1 1 0 011-1h62a1 1 0 011 1v4a1 1 0 01-1 1zm-61-2h60v-2H2v2z"></path>
      <path d="M8.002 52.492c-2.206 0-4-1.794-4-4a1 1 0 112 0c0 1.103.897 2 2 2s2-.897 2-2a1 1 0 112 0c0 2.206-1.794 4-4 4zM55.996 52.492c-2.206 0-4-1.794-4-4a1 1 0 112 0c0 1.103.897 2 2 2s2-.897 2-2a1 1 0 112 0c0 2.206-1.794 4-4 4zM48.979 23.922a1 1 0 01-1-1v-9.416a1 1 0 112 0v9.416a1 1 0 01-1 1zM52.98 23.922a1 1 0 01-1-1v-9.416a1 1 0 112 0v9.416a1 1 0 01-1 1z"></path>
      <path d="M52.98 14.506h-4.001a1 1 0 110-2h4.001a1 1 0 110 2zM45.978 28.506a1 1 0 01-1-1 6.001 6.001 0 013.599-5.5 1 1 0 01.8 1.833 4.003 4.003 0 00-2.399 3.667 1 1 0 01-1 1zM55.977 28.506a1 1 0 01-1-1c0-1.591-.942-3.03-2.399-3.667a1 1 0 01.8-1.833 6.001 6.001 0 013.599 5.5 1 1 0 01-1 1z"></path>
      <path d="M45.978 45.496a1 1 0 01-1-1v-16.99a1 1 0 112 0v16.99a1 1 0 01-1 1zM55.977 45.496a1 1 0 01-1-1v-16.99a1 1 0 112 0v16.99a1 1 0 01-1 1zM52.98 20.506h-4.004a1 1 0 110-2h4.004a1 1 0 110 2z"></path>
      <path d="M45.978 33.506a1 1 0 01-.287-1.958l9.999-3a1.001 1.001 0 01.574 1.916l-9.999 3a1.028 1.028 0 01-.287.042zM45.978 37.51a1 1 0 01-.287-1.958l9.999-3a1 1 0 11.574 1.916l-9.999 3a1.028 1.028 0 01-.287.042zM45.978 41.51a1 1 0 01-.287-1.958l9.999-3a1 1 0 11.574 1.916l-9.999 3a1.028 1.028 0 01-.287.042zM15.005 21.508a1 1 0 01-1-1v-5a1 1 0 112 0v5a1 1 0 01-1 1zM19.006 21.508a1 1 0 01-1-1v-5a1 1 0 112 0v5a1 1 0 01-1 1zM8.004 24.508a1 1 0 01-1-1c0-2.206 1.794-4 4-4a1 1 0 110 2c-1.103 0-2 .897-2 2a1 1 0 01-1 1zM26.007 24.506a1 1 0 01-1-1 2 2 0 00-2-1.998 1 1 0 110-2c2.206 0 4 1.793 4 3.998a1 1 0 01-1 1z"></path>
      <path d="M15.005 21.508h-4.001a1 1 0 110-2h4.001a1 1 0 110 2zM23.007 21.508h-4.002a1 1 0 110-2h4.002a1 1 0 110 2zM8.004 45.496a1 1 0 01-1-1V23.508a1 1 0 112 0v20.988a1 1 0 01-1 1zM26.007 45.496a1 1 0 01-1-1v-20.99a1 1 0 112 0v20.99a1 1 0 01-1 1zM20.005 16.508h-6a1 1 0 01-1-1v-3a1 1 0 011-1h6a1 1 0 011 1v3a1 1 0 01-1 1zm-5-2h4v-1h-4v1z"></path>
      <path d="M26.006 29.506H8.004a1 1 0 110-2h18.002a1 1 0 110 2zM26.006 39.51H8.004a1 1 0 110-2h18.002a1 1 0 110 2zM17.005 36.51c-2.851 0-5.001-1.291-5.001-3.002 0-1.709 2.15-2.998 5.001-2.998s5 1.289 5 2.998c0 1.711-2.15 3.002-5 3.002zm0-4c-1.937 0-3.001.75-3.001.998 0 .249 1.064 1.002 3.001 1.002 1.936 0 3-.753 3-1.002 0-.248-1.065-.998-3-.998zM33.99 25.924a1 1 0 01-1-1v-7.416a1 1 0 112 0v7.416a1 1 0 01-1 1zM37.99 25.924a1 1 0 01-1-1v-7.416a1 1 0 112 0v7.416a1 1 0 01-1 1z"></path>
      <path d="M38.99 18.508h-6a1 1 0 110-2h6a1 1 0 110 2zM30.99 30.508a1 1 0 01-1-1 6.001 6.001 0 013.6-5.5 1 1 0 01.8 1.833 4.002 4.002 0 00-2.4 3.667 1 1 0 01-1 1zM40.99 30.508a1 1 0 01-1-1c0-1.591-.942-3.03-2.4-3.667a1 1 0 01.8-1.833 6.003 6.003 0 013.6 5.5 1 1 0 01-1 1z"></path>
      <path d="M30.99 45.496a1 1 0 01-1-1V29.508a1 1 0 112 0v14.988a1 1 0 01-1 1zM40.99 45.496a1 1 0 01-1-1V29.508a1 1 0 112 0v14.988a1 1 0 01-1 1zM37.99 23.508h-4.002a1 1 0 110-2h4.002a1 1 0 110 2zM37.985 33.502a1 1 0 01-1-1 .998.998 0 00-1.995 0 1 1 0 11-2 0 3 3 0 012.997-2.996 3 3 0 012.998 2.996 1 1 0 01-1 1zM35.987 40.504a3.001 3.001 0 01-2.997-2.998 1 1 0 112 0 .998.998 0 001.995 0 1 1 0 112 0 3 3 0 01-2.998 2.998z"></path>
      <path d="M33.99 38.506a1 1 0 01-1-1v-5.004a1 1 0 112 0v5.004a1 1 0 01-1 1zM37.985 38.506a1 1 0 01-1-1v-5.004a1 1 0 112 0v5.004a1 1 0 01-1 1z"></path>
    </svg>
  );
};

export default AlcoholIcon;
