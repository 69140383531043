import { useTranslation } from "react-i18next";
import { Footer, ItemsWrapper, MenuItemsContainer, Wrapper } from "./styles";

const ErrorScreen = () => {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <MenuItemsContainer>
          <img
            src="/straftaPhotos/Strafta.png"
            alt="not found"
            width={200}
            loading="lazy"
          />
          <ItemsWrapper>
            <p>Ovaj sadržaj je dostupan samo za mobilne uređaje.</p>
          </ItemsWrapper>
        </MenuItemsContainer>
      </Wrapper>
      <Footer>
        <a href="https://www.instagram.com/pixelvizija_/">{t("Copyright")}</a>
      </Footer>
    </>
  );
};

export default ErrorScreen;
