export const breakfastItem = [
  {
    title: "Štrafta doručak",
    subtitle: "350g",
    description: "Jaja, tost, avokado, pršuta, pohovani kačkavalj",
    price: "5,50€",
    isFirst: true,
  },
  {
    title: "Benedikt jaja slanina",
    subtitle: "350g",
    description: "poširana jaja, tost, gvakamole sos, holandez sos",
    price: "4,90€",
  },
  {
    title: "Benedikt jaja pršuta",
    subtitle: "350g",
    description: "poširana jaja, tost, gvakamole sos, holandez sos",
    price: "5,40€",
  },
  {
    title: "Benedikt jaja losos",
    subtitle: "350g",
    description: "poširana jaja, tost, gvakamole sos, holandez sos",
    price: "6,40€",
  },
  {
    title: "Omlet iz Đenove",
    subtitle: "300g",
    description: "jaja, tost, šunka, trapist, pesto sos",
    price: "4,50€",
  },
  {
    title: "Omlet sa šunkom i sirom",
    subtitle: "300g",
    description: "jaja, šunka, trapist , pavlaka",
    price: "4,00€",
  },
  {
    title: "Omlet sa sirom",
    subtitle: "300g",
    description: "Jaja, trapist, pavlaka",
    price: "3,70€",
  },
  {
    title: "Omlet sa povrćem",
    subtitle: "300g",
    description: "jaja, trapist, povrće",
    price: "4,00€",
  },
  {
    title: "Sport doručak",
    subtitle: "300g",
    description: "bjelanca, tikvica, losos, pavlaka",
    price: "6,30€",
  },
  {
    title: "Engleski doručak",
    subtitle: "300g",
    description: "3 jaja, 2 kobasice, feta",
    price: "4,80€",
  },
  {
    title: "Puter kiflice",
    subtitle: "250g",
    description: "sir, pršut, trapist, tartar sos",
    price: "4,50€",
  },
  {
    title: "Punjene prženice",
    subtitle: "300g",
    description: "šunka, trapist, pavlaka",
    price: "3,80€",
  },
  {
    title: "Štapići na kajmaku",
    subtitle: "300g",
    description: "piletina, kajmak, lepinja/ svinjski vrat, kajmak, lepinja",
    price: "6,70€",
  },
];

export const StratersItems = [
  {
    title: "Starters Štrafta",
    subtitle: "500g",
    description:
      "svinjski i govedji pršut, kulen, gorgonzola, mocarela, parmezan, badem, feta, masline, čeri, fokaća",
    price: "14,00€",
    isFirst: true,
  },
  {
    title: "Selekcija sireva",
    subtitle: "450g",
    description:
      "gorgonzola, mocarela, gauda, feta, parmezan, masline, indijski orah, čeri",
    price: "11,50€",
  },
  {
    title: "Pohovani kačkavalj",
    subtitle: "350g",
    description: "sir, pomfrit, tartar sos",
    price: "5,50€",
  },
  {
    title: "Brusketi",
    subtitle: "200g",
    description: "mocarela, paradajz, masline",
    price: "4,40€",
  },
  { title: "Grilovano povrće", subtitle: "250g", price: "3,50€" },
  {
    title: "Punjene pečurke",
    subtitle: "250g",
    description: "pečurke, riža, sir, pršut",
    price: "5,60€",
  },
];

export const SoupsItems = [
  { title: "Teleća čorba", price: "3,50€", isFirst: true },
  { title: "Minestrone", price: "3,00€" },
  { title: "Potaž od paradajza", price: "3,30€" },
];

export const salatItems = [
  { section: "Obrok salate" },
  {
    title: "Ala Chef",
    subtitle: "400g",
    description: "salata, piletina, pečurke, dimljeni sir, šunka, dresing",
    price: "7,00€",
    isFirst: true,
  },
  {
    title: "Biftek salata",
    subtitle: "400g",
    description:
      "miks salata, dresing, biftek, mocarela, badem, krutoni, parmezan",
    price: "9,50€",
  },
  {
    title: "Štrafta salata",
    subtitle: "400g",
    description:
      "miks salata, dresing, pohovana feta, hrskava piletina, indijski orah,krutoni, parmezan",
    price: "7,80€",
  },
  {
    title: "Cezar salata",
    subtitle: "400g",
    description:
      "miks salata, dresing, mocarela, piletina, slanina, krutoni,badem parmezan",
    price: "7,00€",
  },
  {
    title: "Losos salata",
    subtitle: "400g",
    description:
      "miks salata, dresing, avokado, dimljeni losos, sjemenke bundeve",
    price: "8,00€",
  },
  {
    title: "Caprese salata",
    subtitle: "250g",
    description: "mocarela, paradajz, pesto sos, bosiljak",
    price: "5,50€",
  },
  { section: "Sezonske salate" },
  { title: "Šopska salata", subtitle: "250g", price: "3,50€", isFirst: true },
  { title: "Miks salata", subtitle: "250g", price: "3,00€" },
  { title: "Paradajz salata", subtitle: "250g", price: "2,50€" },
];
export const mainDishes = [
  {
    title: "Biftek na žaru",
    subtitle: "400g",
    description: "biftek, pire od tartufa",
    price: "18,00€",
    isFirst: true,
  },
  {
    title: "Biftek u ulju",
    subtitle: "400g",
    description: "biftek, pire",
    price: "22,00€",
  },
  {
    title: "Biftek u sosu",
    description: "-gorgonzole  -pečuraka   -4 vrste sira   -pomorandže",
    price: "22,00€",
  },
  {
    title: "Teleći medaljoni",
    subtitle: "400g",
    description: "teleće meso, neutralna pavlaka, pečurke, aromatični krompir",
    price: "17,00€",
  },
  {
    title: "Ramstek",
    subtitle: "450g",
    description: "Teleće meso, pirinač, povrće",
    price: "15,00€",
  },
  {
    title: "Punjeni pileći stek u sosu od pomorandže",
    subtitle: "400g",
    description: "piletina, pršuta, sir, pire, sos",
    price: "9,00€",
  },
  {
    title: "Batak u sosu",
    subtitle: "400g",
    description: "batak, pire, cary sos",
    price: "9,50€",
  },
  {
    title: "Saltimboka Štrafta",
    subtitle: "400g",
    description:
      "svinjski vrat, pršuta, parmezan, soya sos, aromatični krompir",
    price: "9,00€",
  },
  {
    title: "Vok Šangaj",
    subtitle: "500g",
    description: "3 vrste mesa, nudle, miks povrća, kikiriki, susam",
    price: "9,70€",
  },
  {
    title: "Hrskava piletina",
    subtitle: "350g",
    description: "piletina,  kroketi, tartar sos, kornifleks",
    price: "8,50€",
  },
  {
    title: "Ražnjići",
    subtitle: "400g",
    description: "Piletina, pančeta, paprika, pomfrit",
    price: "8,20€",
  },
  {
    title: "Piletina u gorgonzoli",
    subtitle: "350g",
    description: "piletina, pirinač, pomfrit, gorgonzola, neutralna pavlaka",
    price: "8,60€",
  },
  {
    title: "Piletina u sosu od spanaća",
    subtitle: "350g",
    description: "piletina, spanać, pomfrit, neutralna pavlaka",
    price: "8,00€",
  },
  {
    title: "Piletina sa susamom i bademom",
    subtitle: "350g",
    description: "piletina, neutrala pavlaka, susam, badem",
    price: "8,20€",
  },
];

export const tortillasItems = [
  { section: "Sendviči" },
  {
    title: "Sendvič Štrafta",
    subtitle: "300g",
    description: "lepinja, šunka, topljeni sir, pesto sos, rukola, pomfrit",
    price: "5,20€",
    isFirst: true,
  },
  {
    title: "Sendvič Sicilija",
    subtitle: "300g",
    description: "Lepinja, namaz, pršuta, sir, salata, pomfrit",
    price: "4,90€",
  },
  {
    title: "Pizza sendvič",
    subtitle: "350g",
    description: "lepinja, namaz, pršuta, pomfrit",
    price: "4,80€",
  },
  {
    title: "Rich sendvič",
    subtitle: "300g",
    description: "lepinja, trapist, pohovana piletina, pomfrit",
    price: "5,50€",
  },
  {
    title: "Klub sendvič",
    subtitle: "350g",
    description: "tost, piletina, jaje, šunka, trapist, pomfrit",
    price: "4,80€",
  },
  { section: "TORTILJE" },
  {
    title: "Tortilja u cary sosu",
    subtitle: "450g",
    description:
      "domaća tortilja, piletina, neutralna pavlaka, zelena salata paradajz, pomfrit",
    price: "5,80€",
    isFirst: true,
  },
  {
    title: "Tortilja u pesto sosu",
    subtitle: "450g",
    description: "domaća tortilja, sir, piletina, salata, pesto sos, pomfrit",
    price: "5,50€",
  },
  {
    title: "Tortilja piletina i gvakamole",
    subtitle: "450g",
    description: "domaća tortilja, gvakamole sos, piletina, salata, pomfrit",
    price: "5,40€",
  },
  {
    title: "Tortilja tuna",
    subtitle: "450g",
    description: "domaća tortilja, namaz, tunjevina, salata, pomfrit",
    price: "4,90€",
  },
];

export const pizze = [
  {
    title: "Ala Chef",
    subtitle: "600g/350g",
    description: "pelat, sir, pršuta, šunka, pančeta, kulen, pečurke, kajmak ",
    price: "9,20€/6,10€",
    isFirst: true,
  },
  {
    title: "Margarita",
    subtitle: "550g/280g",
    description: "sir, pelat, bosiljak",
    price: "5,70€/3,60€",
  },
  {
    title: "Capricosa",
    subtitle: "600g/300g",
    description: "pelat, sir, šunka, pečurke",
    price: "6,90€/4,90€",
  },
  {
    title: "Pepperoni",
    subtitle: "500g/300g",
    description: "pelat, sir, kulen, ljuta paprika",
    price: "7,20€/5,20€",
  },
  {
    title: "Tonno",
    subtitle: "600g/350g",
    description: "pelat, sir, tunjevina, luk",
    price: "6,60€/4,90€",
  },
  {
    title: "Toscana",
    subtitle: "550g/300g",
    description: "pelat, sir, pršuta, gorgonzola, rukola",
    price: "7,30€/6,60€",
  },
  {
    title: "Quatro formagi",
    subtitle: "500g/300g",
    description: "pelat, gauda, gorgonzola, mocarela, parmeza",
    price: "6,80€/4,80€",
  },
  {
    title: "Vegeteriana",
    subtitle: "600g/350g",
    description: "pelat, sir, povrće",
    price: "6,400€/4,60€",
  },
  {
    title: "Calcone",
    subtitle: "500g/350g",
    price: "6,90€/4,90€",
  },
];

export const pastaAndRisoto = [
  { section: "Pasta" },
  {
    title: "Ala Chef pasta",
    subtitle: "400g",
    description: "piletina, pečurke,tikvice, neutralna pavlaka",
    price: "7,00€",
    isFirst: true,
  },
  {
    title: "Pasta ai frutti di mare",
    subtitle: "400g",
    description: "morski plodovi, pasta, crveni sos",
    price: "7,70€",
  },
  {
    title: "Arabiata pasta",
    subtitle: "400g",
    description: "pasta, paprika, pelat, čili",
    price: "5,00€",
  },
  {
    title: "Tagliatelle carbonara",
    subtitle: "400g",
    description: "slanina, pavlaka neutralna, parmezan, jaje",
    price: "5,50€",
  },
  {
    title: "Spaghetti Pollo e Pesto",
    subtitle: "400g",
    description: "piletina, neutralna pavlaka, pesto sos, indijski orah",
    price: "6,80€",
  },
  {
    title: "Njoke Formaggio e Pollo",
    subtitle: "400g",
    description: "piletina, 4 vrste sira, badem, njoke",
    price: "6,90€",
  },
  {
    title: "Farfalle Vittelo e Fungi",
    subtitle: "400g",
    description: "biftek, neutralna pavlaka, pečurke, soya sos, parmezan",
    price: "9,90€",
  },
  {
    title: "Tagliatelle  di tacchino arrosto",
    subtitle: "400g",
    description: "pečurke, piletina, neutralna pavlaka, mocarela",
    price: "6,50€",
  },
  { section: "Rižoto" },
  {
    title: "Risotto Formagio e Pollo",
    subtitle: "400g",
    description: "piletina, 4 vrste sira, neutralna pavlaka, parmezan",
    price: "6,90€",
    isFirst: true,
  },
  {
    title: "Risotto tacchino e Pesto",
    subtitle: "400g",
    description: "piletina, pesto sos, neutralna pavlaka, parmezan",
    price: "6,50€",
  },
  {
    title: "Risotto Vittelo e Fung",
    subtitle: "400g",
    description: "biftek, sos od pečurki, neutralna pavlaka, riža",
    price: "9,50€",
  },
  {
    title: "Risotto nero ai frutti di mare",
    subtitle: "400g",
    description: "crnilo od sipe, morski plodovi, riža",
    price: "8,00€",
  },
  {
    title: "Risotto Rosso ai frutti di mare",
    subtitle: "400g",
    description: "crveni sos, morski plodovi, riža",
    price: "8,00€",
  },
];

export const fishItems = [
  {
    title: "Losos na žaru",
    subtitle: "350g",
    description: "Losos, mediteransko varivo",
    price: "13,00€",
    isFirst: true,
  },
  {
    title: "Tuna stek",
    subtitle: "350g",
    description: "tuna, mediteransko varivo",
    price: "18,00€",
  },
  {
    title: "Lignje na žaru",
    subtitle: "350g",
    description: "lignje, dalmatinsko varivo",
    price: "14,50€",
  },
];

export const sidedishesItems = [
  {
    title: "Pomfrit",
    subtitle: "300g",
    price: "2,80€",
    isFirst: true,
  },
  {
    title: "Ekstra prilog",
    price: "1,00€",
  },
  {
    title: "Masline",
    subtitle: "100g",
    price: "1,00€",
  },
  {
    title: "Pica hljeb",
    subtitle: "100g",
    price: "1,20€",
  },
  {
    title: "Fokaća",
    subtitle: "200g",
    price: "1,90€",
  },
  {
    title: "Sos po želji",
    price: "2,00€",
  },
  {
    title: "Kajmak",
    price: "3,80€",
  },
  {
    title: "Pršuta",
    subtitle: "100g",
    price: "4,20€",
  },
];

export const dessertItems = [
  {
    title: "Palačinka",
    subtitle: "250g",
    description: "nutela, plazma",
    price: "3,20€",
    isFirst: true,
  },
  {
    title: "Američke palačinke",
    subtitle: "250g",
    description: "eurokrem,plazma",
    price: "3,50€",
  },
  {
    title: "Američke palačinke",
    subtitle: "250g",
    description: "banananom i bademom, eurokrem,plazma",
    price: "3,90€",
  },
  {
    title: "Američke palačinke sa voćem",
    subtitle: "250g",
    description: "eurokrem, voće, šlag",
    price: "3,90€",
  },
  {
    title: "Oreo",
    subtitle: "200g",
    description: "oreo keks, abc sir, bijela čokolada, slatka pavlaka",
    price: "2,80€",
  },
  {
    title: "Bluebery cake",
    subtitle: "200g",
    description: "plazma, bijela čokolada, grčki jogurt, borovnice",
    price: "2,80€",
  },
  {
    title: "Jagode sa šlagom",
    price: "3,50€",
  },
  {
    title: "Voćna salata",
    price: "3,50€",
  },
];
